import { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ArrowSmall from '../../assets/screening/ArrowSmall';
import DropDownArrow from '../../assets/screening/DropDownArrow';
import Ticket from '../Ticket/Ticket';
import DeleteForever from '../../assets/screening/DeleteForever';
import { formatMinutesToHours } from '../../utils/formatMinutesToHours';
import { t } from '../../i18n/i18n';
import './TestGroupsListItem.scss';
import useCreateScreening from '../../hooks/useCreateScreening';
import { TestGroupTypes } from '../../constants/enums';
import QuestionToggleIcon from '../../assets/screening/QuestionToggleIcon';
import { QuestionEnableModal } from './QuestionEnableModal';
import { questionsAmount } from '../../utils/tests';
import useTranslations from '../../hooks/useTranslations';

const TestGroupsListItem = ({
  testGroup,
  disableOrderUp,
  disableOrderDown,
  disableQuestionIcon = false,
  disableCustomWeight,
  hideOrderArrows,
  hideDeleteIcon,
  handleOrder,
  handleDelete,
  handleQuestionButton,
  weightCustomWidth = false,
}) => {
  const { key } = useTranslations();
  const content = useRef(null);
  const { modifyTestWeight } = useCreateScreening();
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleActiveSkipQuestion = ({ hasSkipQuestion }) => {
    handleQuestionButton({ newItem: { ...testGroup, hasSkipQuestion } });
    setShowModal(false);
  };

  return (
    <div className="d-flex w-100 align-items-center justify-content-between">
      <div className="align-items-center d-flex justify-content-between my-1 flex-grow-1 flex-wrap">
        <div className="d-flex bg-gray px-4 py-3 w-100 align-items-center justify-content-between container-min-height">
          <span
            className="text-muted font-montserrat text-xs font-weight-bold text-truncate test-name-max-width mb-2 mb-md-0"
            data-testid="tg-list-item-name-span"
          >
            {testGroup?.type === TestGroupTypes.PRE_FILTER
              ? t('TESTGROUP_PRE_FILTER_TITLE_NAME')
              : testGroup.name}
          </span>
          <div className="d-flex align-items-center">
            {testGroup?.category && (
              <Ticket
                className="ticket-test-tech font-weight-bold font-roboto text-xs px-2"
                text={testGroup?.category?.name}
              />
            )}
            {Boolean(testGroup?.tags?.length) && (
              <Ticket
                className="ticket-technology-text font-weight-bold font-roboto ml-2 py-0 text-uppercase text-xs px-2"
                text={testGroup?.tags[0]?.displayName}
              />
            )}
            {testGroup?.type === 'custom' && (
              <Ticket
                className="ticket-test-tech font-weight-bold font-roboto text-xs px-2"
                text={t('CREATE_SCREENING_CUSTOM_TEST_GROUP_LABEL')}
              />
            )}
            {[TestGroupTypes.QUALITATIVE, TestGroupTypes.PRE_FILTER].includes(testGroup?.type) ? (
              <span
                className={`qualitative-label ml-2 font-montserrat font-italic text-sm font-weight-500 ${
                  !weightCustomWidth ? 'qualitative-label-width' : ''
                }`}
              >
                {t(`CREATE_SCREENING_${testGroup.type}_LABEL`)}
              </span>
            ) : (
              <div
                className={`${
                  weightCustomWidth ? 'weight-input-custom-width' : 'time-input-container'
                } font-montserrat ml-2 py-1 font-weight-bold ${
                  disableCustomWeight ? 'disabled-weight-input' : 'maya-blue-thin-border'
                }`}
                data-testid="tg-list-item-weight-input"
              >
                <div
                  className={`d-flex justify-content-between px-3 ${
                    weightCustomWidth ? 'weight-input-custom-width' : 'time-input-container'
                  } align-items-center`}
                >
                  <input
                    disabled={disableCustomWeight}
                    className={`percentage-input-width bg-transparent border-0 outline-none font-montserrat text-sm text-italic font-weight-bold ${
                      !disableCustomWeight && 'total-number-text'
                    }`}
                    min="0"
                    max="100"
                    value={testGroup?.weight ?? ''}
                    onKeyDown={(e) => {
                      const excludedKeys = ['-', '+', 'e'];
                      if (excludedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => modifyTestWeight(testGroup?.id, e.target.value)}
                    type="number"
                    data-testid="tg-list-item-custom-weight-input"
                  />
                  <span
                    className="text-base font-montserrat font-weight-bold"
                    data-testid="tg-list-item-percent-span"
                  >
                    %
                  </span>
                </div>
              </div>
            )}
            <button
              onClick={() => setOpen(!open)}
              type="button"
              className="ml-3 bg-transparent border-0 outline-none"
              data-testid="tg-list-item-open-btn"
            >
              <DropDownArrow classNames={`transition-2-ms ${open && 'rotate-180'}`} />
            </button>
          </div>
        </div>
        <div
          ref={content}
          style={{ height: open ? `${content?.current?.scrollHeight}px` : '0px' }}
          className={`d-flex px-4 position-relative overflow-hidden w-100 flex-column justify-content-center ${
            open && 'py-3 testgroup-description-border-4step h-100'
          }`}
        >
          {testGroup?.languages?.length ? (
            <>
              {testGroup.summary && (
                <span
                  className="font-montserrat text-sm text-muted text-italic text-break"
                  data-testid="tg-list-item-tg-description-span"
                >
                  {testGroup?.type === TestGroupTypes.QUALITATIVE && !weightCustomWidth
                    ? t('CREATE_SCREENING_QUALITATIVE_DESCRIPTION')
                    : testGroup.summary[key] ?? ''}
                </span>
              )}
            </>
          ) : (
            testGroup?.description && (
              <span
                className="font-montserrat text-sm text-muted text-italic text-break"
                data-testid="tg-list-item-tg-description-span"
              >
                {testGroup?.type === TestGroupTypes.QUALITATIVE && !weightCustomWidth
                  ? t('CREATE_SCREENING_QUALITATIVE_DESCRIPTION')
                  : testGroup.description}
              </span>
            )
          )}

          {testGroup?.type === 'custom' && (
            <span
              className="font-montserrat text-sm text-muted text-italic"
              data-testid="tg-list-item-type-custom-span"
            >
              {disableCustomWeight
                ? t('CREATE_SCREENING_CUSTOM_TESTGROUP_DESCRIPTION_WITHOUT_WEIGHT')
                : t('CREATE_SCREENING_CUSTOM_TESTGROUP_DESCRIPTION_WITH_WEIGHT')}
            </span>
          )}
          <div className="mt-2 d-flex flex-wrap">
            {questionsAmount(testGroup) !== 0 && (
              <div className="mr-3">
                <span className="font-montserrat text-sm" data-testid="tg-list-item-quantity-span">
                  {t('CREATE_SCREENING_GENERAL_QUESTION_QUANTITY')}
                </span>
                <span
                  className="font-montserrat text-sm text-italic font-weight-bold total-number-text"
                  data-testid="tg-list-item-quantity-number-span"
                >
                  {questionsAmount(testGroup)}
                </span>
              </div>
            )}
            <div className="mr-3">
              <span className="font-montserrat text-sm" data-testid="tg-list-item-quantity-span">
                {t('CREATE_SCREENING_GENERAL_AVAILABLE_QUESTION_QUANTITY')}
              </span>
              <span
                className="font-montserrat text-sm text-italic font-weight-bold total-number-text"
                data-testid="tg-list-item-quantity-number-span"
              >
                {testGroup?.tests?.length ?? testGroup?.testsQuantity}
              </span>
            </div>
            <div className="ml-3">
              <span className="font-montserrat text-sm" data-testid="tg-list-item-tg-duration-span">
                {t('CREATE_SCREENING_GENERAL_TEST_DURATION')}
              </span>
              <span
                className="font-montserrat text-sm text-italic font-weight-bold total-number-text"
                data-testid="tg-list-item-tg-duration-number-span"
              >
                {testGroup?.type !== TestGroupTypes.PRE_FILTER
                  ? formatMinutesToHours(testGroup?.duration ?? 0)
                  : t('NO_TIME_RESTRICTION')}
              </span>
            </div>
          </div>
          {testGroup?.tests && disableQuestionIcon && (
            <div className="d-flex flex-column w-full  mt-1 mb-1">
              <div className="d-flex flex-column w-full  my-1 bg-white">
                <span className="d-block font-montserrat font-weight-800 text-md text-color-item-test-group text-truncate media-body my-2">
                  {t('QUESTIONS')}
                </span>
                {testGroup?.tests?.map((item) => (
                  <div
                    key={item.testId}
                    className="mb-2 justify-content-between d-flex flex-row justify-content-between align-items-center border-item-test-group p-3 position-relative"
                    aria-hidden="true"
                  >
                    <span className="d-block font-montserrat font-weight-600 text-sm text-color-item-test-group text-truncate media-body">
                      {item?.test?.text}
                    </span>
                    <span className="ticket-technology-frontend ticket-padding font-weight-bold font-roboto text-uppercase px-3 mr-auto mr-md-0 mt-2 mt-md-0 ml-2">
                      {item?.test?.type === TestGroupTypes.PRE_FILTER
                        ? t(`CREATE_PREFILTER_QUESTIONS_${item.test?.answerType}`)
                        : t(`CREATE_SCREENING_YOUR_QUESTIONS_${item.test?.answerType}`)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="ml-2 align-self-start mt-3 flex-shrink-0 py-2 pl-1">
        {!hideOrderArrows && (
          <>
            <button
              disabled={disableOrderDown}
              className="bg-transparent border-0 outline-none mr-1"
              type="button"
              onClick={() => handleOrder(testGroup.order, testGroup.order + 1, 'allTests')}
              data-testid="tg-list-item-disabled-order-down-btn"
            >
              <ArrowSmall color={disableOrderDown ? '#828282' : '#4bafe1'} />
            </button>
            <button
              disabled={disableOrderUp}
              className="bg-transparent border-0 outline-none ml-1"
              type="button"
              onClick={() => handleOrder(testGroup.order, testGroup.order - 1, 'allTests')}
              data-testid="tg-list-item-disabled-order-up-btn"
            >
              <ArrowSmall classNames="rotate-180" color={disableOrderUp ? '#828282' : '#4bafe1'} />
            </button>
          </>
        )}
        {!disableQuestionIcon && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {testGroup?.hasSkipQuestion ?? true ? t('ACTIVATED_LABEL') : t('DEACTIVATED_LABEL')}
              </Tooltip>
            }
          >
            <button
              onClick={() => !disableQuestionIcon && setShowModal(true)}
              type="button"
              className="bg-transparent border-0 outline-none ml-3"
              data-testid="tg-list-item-delete-btn"
            >
              <QuestionToggleIcon
                cancelled={!(testGroup?.hasSkipQuestion ?? true)}
                style={{
                  color: testGroup?.type === TestGroupTypes.PRE_FILTER ? 'gray' : 'initial',
                }}
              />
            </button>
          </OverlayTrigger>
        )}
        {!disableQuestionIcon && testGroup?.type !== TestGroupTypes.PRE_FILTER && (
          <QuestionEnableModal
            data={{ x: '' }}
            setShowModal={setShowModal}
            showModal={showModal}
            submitAction={() => {
              handleActiveSkipQuestion({
                hasSkipQuestion:
                  testGroup?.hasSkipQuestion === undefined ? false : !testGroup?.hasSkipQuestion,
              });
            }}
            isQuestionEnabled={testGroup?.hasSkipQuestion ?? true}
          />
        )}

        {!hideDeleteIcon && (
          <button
            onClick={handleDelete}
            type="button"
            className="bg-transparent border-0 outline-none ml-3"
            data-testid="tg-list-item-delete-btn"
          >
            <DeleteForever />
          </button>
        )}
      </div>
    </div>
  );
};

export default TestGroupsListItem;
