import { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { generateMinutes } from '../../constants/hours';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';
import AditionalQuestions from './AditionalQuestions/AditionalQuestions';
import PrefilterQuestions from './PrefilterQuestions/PrefilterQuestions';
import './PrefilterQuestions/PrefilterQuestions.css';
import GenericToggle from '../GenericToggle/GenericToggle';
import DisabledFeature from '../DisabledFeature/DisabledFeature';
import { PlanFeaturesIds } from '../../constants/enums';

const AditionalQuestionHeader = () => (
  <div className="py-3 w-100 align-items-center justify-content-between panel-header">
    <span
      className="font-montserrat font-weight-600 text-base text-light-black create-evaluation-step-title"
      data-testid="create-evaluation-ts-custom-test-title-span"
    >
      {t('CREATE_EVALUATION_THIRD_STEP_CUSTOM_TEST_TITLE')}
    </span>
    <span
      className="font-montserrat font-weight-400 font-italic text-xs text-muted ml-2"
      data-testid="create-evaluation-ts-custom-comment-span"
    >
      {t('CREATE_EVALUATION_THIRD_STEP_CUSTOM_TEST_TITLE_COMMENT')}
    </span>
  </div>
);

const PreFilterTestHeader = () => (
  <div className="py-3 w-100 mb-2 align-items-center justify-content-between panel-header">
    <span
      className="font-montserrat font-weight-600 text-base text-light-black"
      data-testid="create-evaluation-ts-custom-test-title-span"
    >
      {t('CREATE_EVALUATION_THIRD_STEP_PREFILTER_TEST_TITLE')}
    </span>
    <span
      className="font-montserrat font-weight-400 font-italic text-xs text-muted ml-2"
      data-testid="create-evaluation-ts-custom-comment-span"
    >
      {t('CREATE_EVALUATION_THIRD_STEP_CUSTOM_TEST_TITLE_COMMENT')}
    </span>
  </div>
);

const CreateEvaluationThirdStep = () => {
  const {
    newScreening,
    incompleteQuestion,
    setEditData,
    handleOrder,
    handleDelete,
    handleCustomScreeningsTime,
    fetchPrefilterQuestions,
    handleAddPrefilterQuestion,
    handleOrderByIndex,
    handleAddPrefilterResponseDelay,
    handleDeletePrefilterQuestion,
  } = useCreateScreening();
  const [activeKeys, setActiveKeys] = useState(
    newScreening?.prefilterQuestions?.length > 0 ? ['1', '2'] : ['2']
  );
  const { Panel } = Collapse;

  const handleCollapseState = (key) => {
    if (activeKeys.includes(key)) {
      setActiveKeys(activeKeys.filter((item) => item !== key));
    } else {
      setActiveKeys([...key]);
    }
  };

  useEffect(() => {
    if (!newScreening.prefilterQuestionsCompanyOptions?.length) {
      fetchPrefilterQuestions();
    }
  }, []);

  return (
    <div data-testid="create-evaluation-third-step" className="d-flex flex-column">
      <span
        className="font-weight-600 font-montserrat create-evaluation-step-title text-light-black mb-3"
        data-testid="create-evaluation-ts-title-span"
      >
        {t('CREATE_EVALUATION_THIRD_STEP_TITLE')}
      </span>
      <Collapse
        defaultActiveKey={activeKeys}
        expandIcon={({ isActive }) => (
          <GenericToggle
            defaultActive={isActive}
            disabled={
              isActive &&
              (newScreening?.prefilterQuestions?.length > 0 ||
                newScreening?.customTestGroup?.length > 0)
            }
            actionFunction={() => {}}
          />
        )}
        onChange={handleCollapseState}
        expandIconPosition="end"
        bordered={false}
        className="px-4 py-1 w-100 align-items-center justify-content-between mb-2 bg-white"
      >
        <Panel
          header={<PreFilterTestHeader questionsAmount={newScreening?.customTestGroup?.length} />}
          key="1"
          className="panel-container bg-gray border-none "
          collapsible={
            activeKeys.includes('1') && newScreening?.prefilterQuestions?.length > 0
              ? 'disabled'
              : ''
          }
        >
          <DisabledFeature
            className="d-flex w-100 align-items-center"
            childrenClassname="w-100"
            featureId={PlanFeaturesIds.SEARCH_STATS}
          >
            <PrefilterQuestions
              prefilterQuestions={newScreening.prefilterQuestionsCompanyOptions}
              handleAddPrefilterQuestion={handleAddPrefilterQuestion}
              questionsDraft={newScreening.prefilterQuestions}
              delayTime={newScreening.prefilterQuestionsDelay}
              handleOrder={handleOrderByIndex}
              handleAddDelay={handleAddPrefilterResponseDelay}
              handleDelete={handleDeletePrefilterQuestion}
            />
          </DisabledFeature>
        </Panel>
        <Panel
          header={
            <AditionalQuestionHeader questionsAmount={newScreening?.customTestGroup?.length} />
          }
          key="2"
          className="panel-container bg-gray "
          collapsible={
            activeKeys.includes('2') && newScreening?.customTestGroup?.length > 0 ? 'disabled' : ''
          }
        >
          <AditionalQuestions
            newScreening={newScreening}
            incompleteQuestion={incompleteQuestion}
            setEditData={setEditData}
            handleOrder={handleOrder}
            handleDelete={handleDelete}
            handleCustomScreeningsTime={handleCustomScreeningsTime}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default CreateEvaluationThirdStep;
