import React, { useState } from 'react';
import AddQuestions from '../../AddQuestions/AddQuestions';
import { generateMinutes } from '../../../constants/hours';
import { getMinimunScreeningTimeInSeconds } from '../../../utils/time';
import { t } from '../../../i18n/i18n';
import DropdownSelect from '../../DropdownSelect/DropdownSelect';
import TestGroupsItem from '../../TestGroupsItem/TestGroupsItem';
import Accordion from '../../Accordion/Accordion';
import Arrow from '../../../assets/modal/Arrow';

export default function AditionalQuestions({
  newScreening,
  incompleteQuestion,
  setEditData,
  handleOrder,
  handleDelete,
  handleCustomScreeningsTime,
}) {
  const [showDrowdown, setShowDropdown] = useState(false);
  const minCustomScreeningsTimeInSeconds = getMinimunScreeningTimeInSeconds(newScreening);

  const minCustomScreeningsTimeInHours = minCustomScreeningsTimeInSeconds
    ? minCustomScreeningsTimeInSeconds / 3600
    : 0;

  const hours = generateMinutes(5, 2.05, minCustomScreeningsTimeInHours ?? null);

  return (
    <div className="bg-white p-5 rounded-lg">
      <span
        className="text-light-black font-weight-400 text-base my-2 font-montserrat"
        data-testid="create-evaluation-ts-custom-test-subtitle-span"
      >
        {t('CREATE_EVALUATION_THIRD_STEP_CUSTOM_TEST_SUBTITLE')}
      </span>
      <div className="d-flex justify-content-center">
        <AddQuestions incompleteQuestion={incompleteQuestion} />
      </div>
      <div
        className="bg-blue-principal w-100 d-flex justify-content-between align-items-center p-2 cursor-pointer color-white font-weight-600"
        onClick={() => setShowDropdown(!showDrowdown)}
      >
        <div className="d-flex gap-2">
          <span>{`${t('CREATE_SCREENING_YOUR_QUESTIONS_ADDED_QUESTIONS')} ${
            newScreening?.customTestGroup?.length
          } `}</span>
          <span className="mx-2">{` ${t('CREATE_EVALUATION_THIRD_STEP_CUSTOM_SET_TIME')}  ${
            newScreening?.customScreeningTGDuration ?? '00:00'
          }  `}</span>
        </div>
        <Arrow
          className={`${
            showDrowdown ? 'arrow-dropdown-modal-collapsed' : 'arrow-dropdown-modal-uncollapsed'
          }`}
        />
      </div>
      <div className="mt-2">
        {showDrowdown &&
          newScreening.customTestGroup
            .sort((a, b) => a.order - b.order)
            .map((item, index) => (
              <TestGroupsItem
                test={item}
                key={item.id}
                disableOrderUp={index === 0}
                handleEdit={() => setEditData(item)}
                disableOrderDown={index === newScreening.customTestGroup.length - 1}
                handleOrder={handleOrder}
                handleDelete={() => handleDelete(item.deleteId)}
                isComplementaryQuestion
              />
            ))}
      </div>
      <Accordion
        title={t('CREATE_EVALUATION_THIRD_STEP_CUSTOM_SET_TIME')}
        className="mt-3"
        open={showDrowdown}
      >
        <div className="my-1 my-sm-4 ml-0 d-flex justify-content-end align-items-center">
          <span
            className="font-montserrat text-light-black font-weight-500 text-base"
            data-testid="create-evaluation-ts-set-time-span"
          >
            {t('CREATE_EVALUATION_THIRD_STEP_CUSTOM_SET_TIME')}
          </span>
          <DropdownSelect
            options={hours}
            selected={newScreening?.customScreeningTGDuration ?? '00:00'}
            selectedPlaceholder
            setState={handleCustomScreeningsTime}
            className="time-picker-button time-picker-button-small ml-3"
            placeholderClassname="ml-4"
          />
        </div>
      </Accordion>
    </div>
  );
}
