import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { notification } from 'antd';
import { v4 } from 'uuid';
import CreateEvaluationModalImage from '../assets/createEvaluation/CreateEvaluationModalImage';
import { t } from '../i18n/i18n';
import Modal from '../components/Modal/Modal';
import processQueryString from '../utils/processQueryString';
import { CREATE_EVALUATION, SCREENING_SEARCHES } from '../constants/routes';
import { setEvaluationData } from '../redux/actions/evaluationActions';
import {
  editRecruitSessionDraft,
  getRecruitSessionDraft,
  getScreeningTestGroups,
  openEvaluation,
  postRecruitSessionDraft,
} from '../API/services/screeningService';
import useCreateScreening from './useCreateScreening';
import {
  setCustomTestGroupIncompleteQuestion,
  setNewScreeningData,
  setNewSearchData,
  setSearchInvitationsData,
  setSendEmailFrom,
  setTestGroupsData,
} from '../redux/actions/screeningActions';
import { formatMinutesToHours } from '../utils/formatMinutesToHours';
import { formatOrder } from '../utils/formatArrayOrder';
import { TrackEvent } from '../utils/filters/segmentUtils';
import { EvaluationPurposes, ReviewTypeEnum, TestGroupTypes } from '../constants/enums';
import { isNullOrUndefined } from '../utils/typesUtils';
import { getLocalStorageWorkspace } from '../utils/workspaceUtils';
import { convertMinToMinutesNumber } from '../constants/hours';

const useCreateEvaluation = () => {
  const {
    newScreening,
    customScreeningTotalTime,
    availableWeight,
    testsDuration,
    incompleteQuestionValidation,
    incompletePrefilterQuestionValidation,
    formatCustomTestGroupToPost,
    handleClearFilters,
    formatFilters,
  } = useCreateScreening();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingTests, setLoadingTests] = useState(false);
  const [loadingCreateDraft, setLoadingCreateDraft] = useState(false);
  const [loadingCreateEvaluation, setLoadingCreateEvaluation] = useState(false);
  const [preventReload, setPreventReload] = useState(false);
  const { evaluation } = useSelector((state) => state.evaluation);
  const [previousData, setPreviousData] = useState(evaluation);
  const [previousWeightsAndOrders, SetPreviousWeightsAndOrders] = useState([]);
  const [showExitModal, setShowExitModal] = useState(false);
  const [quickTest, setQuickTest] = useState(false);
  const [enableCreateButton, setEnableCreateButton] = useState(false);
  const { user, currentWorkspace } = useSelector((state) => state.auth);
  const { newSearch, newScreeningFilters } = useSelector((state) => state.screenings);
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const queryData = processQueryString(search);
  const parsedCustomTGDuration = convertMinToMinutesNumber(newScreening?.customScreeningTGDuration);

  const customTestGroup = newScreening?.allTests.find(
    (item) => item.type === TestGroupTypes.CUSTOM
  );
  const disableCustomTestGroupWeight =
    customTestGroup &&
    !customTestGroup?.tests?.some((test) =>
      ['CHECK', 'TEXT_AI', 'WRITE'].includes(test.answerType)
    );

  const missingTestgroupWeight = false;

  const validCustomTestGroup = () => {
    const testsListLength = newScreening?.customTestGroup?.length;
    let validTestGroup = false;
    if (
      (testsListLength && parsedCustomTGDuration) ||
      (!testsListLength && !parsedCustomTGDuration)
    ) {
      validTestGroup = true;
    }

    return validTestGroup;
  };

  const existingChanges = !isEqual(
    { ...previousData, weights: previousWeightsAndOrders },
    {
      ...evaluation,
      ...(newScreening?.testGroups?.length ? { testGroupList: newScreening?.testGroups } : {}),
      ...(newScreening?.prefilterQuestions
        ? { prefilterQuestions: newScreening.prefilterQuestions }
        : {}),
      ...(newScreening?.prefilterQuestionsDelay
        ? { prefilterQuestionsDelay: newScreening.prefilterQuestionsDelay }
        : {}),
      ...(newScreening?.customTestGroup?.length
        ? {
            customTestGroup: newScreening?.customTestGroup,
            customTestGroupHasSkipQuestion: newScreening?.customTestGroup?.hasSkipQuestion ?? true,
          }
        : {}),
      ...(newScreening?.customScreeningTGDuration?.length
        ? { customScreeningTGDuration: newScreening?.customScreeningTGDuration }
        : {}),
      weights: newScreening?.allTests?.map((item) => ({
        [item.id]: {
          weight: item.weight,
          order: item.order,
        },
      })),
      ...(newScreening.welcomeVideoUrl && newScreening.welcomeVideoTitle
        ? {
            welcomeVideoTitle: newScreening.welcomeVideoTitle,
            welcomeVideoUrl: newScreening.welcomeVideoUrl,
          }
        : { welcomeVideoTitle: null, welcomeVideoUrl: null }),
    }
  );

  const EvaluationPurposesOptions = [
    {
      id: EvaluationPurposes.TEST_THE_FEATURE,
      name: t('CREATE_EVALUATION_TEST_THE_FEATURE'),
    },
    {
      id: EvaluationPurposes.INTERN_PERSONAL_MAPPING,
      name: t('CREATE_EVALUATION_INTERN_PERSONAL_MAPPING'),
    },
    {
      id: EvaluationPurposes.EXTERNAL_PERSONAL_SEARCH,
      name: t('CREATE_EVALUATION_EXTERNAL_PERSONAL_SEARCH'),
    },
    {
      id: EvaluationPurposes.INTERNAL_PERSONAL_SEARCH,
      name: t('CREATE_EVALUATION_INTERNAL_PERSONAL_SEARCH'),
    },
    {
      id: EvaluationPurposes.MEASURE_SKILLS_FOR_TRAINING_PROGRAM,
      name: t('CREATE_EVALUATION_MEASURE_SKILLS_FOR_TRAINING_PROGRAM'),
    },
    {
      id: EvaluationPurposes.OTHER,
      name: t('CREATE_EVALUATION_OTHER'),
    },
  ];

  const disableEditButton = !existingChanges || !evaluation.name.length || !validCustomTestGroup();

  const disableOpenEvaluation =
    !evaluation.name.length ||
    !validCustomTestGroup() ||
    !newScreening.allTests.length ||
    availableWeight !== 0 ||
    missingTestgroupWeight;

  const resetDateHours = (date) => moment(date).set({ hour: 0, minute: 0 }).toISOString();
  const getDateHours = (date) => moment(date).format('HH:mm');

  const formatCustomTestGroupTests = (testGroupList) =>
    testGroupList?.map((item) => ({
      ...item,
      id: `temp-${v4()}`,
      deleteId: v4(),
      testImagesCount: 0,
      answerType: item.answerType,
    })) ?? [];

  const setFormattedData = (data) => {
    const formattedData = {
      name: data.name,
      startDate: {
        day: resetDateHours(data.startDate),
        hours:
          Number(getDateHours(data.startDate).split(':')[1]) % 15
            ? '00:00'
            : getDateHours(data.startDate),
      },
      endDate: {
        day: resetDateHours(data.endDate),
        hours: getDateHours(data.endDate),
      },
      testGroupList: data.testGroupList?.map((tg) => ({ ...tg, isSelected: true })) ?? [],
      customTestGroup: formatCustomTestGroupTests(data?.customTestGroup?.tests),
      customTestGroupHasSkipQuestion: data?.customTestGroup?.hasSkipQuestion ?? true,
      customScreeningTGDuration: formatMinutesToHours(data?.customTestGroup?.duration ?? 0, false),
      purpose: data.purpose,
      customPurpose: data.customPurpose,
      allowCertificateDownload: data.allowCertificateDownload ?? true,
      proctoringEnabled: data.proctoringEnabled ?? true,
      requireCompletedProfile: data.requireCompletedProfile ?? false,
      reminderToCandidates: data.reminderToCandidates ?? true,
      reviewType: data.reviewType ?? ReviewTypeEnum.GLOBAL,
      emptyDates: data.emptyDates ?? true,
      roleId: data.roleId,
      quickStartRoleId: data.quickStartRoleId,
      prefilterQuestions: data?.prefilterQuestions?.testGroups[0]?.tests,
      prefilterQuestionsDelay: data?.prefilterQuestions?.negativeDelay ?? undefined,
      prefilterQuestionDraftId: data?.prefilterQuestionDraftId,
      welcomeVideoUrl: data.welcomeVideoUrl,
      welcomeVideoTitle: data.welcomeVideoTitle,
    };
    dispatch(setEvaluationData(formattedData));
    setQuickTest(!isNullOrUndefined(data.quickStartRoleId));
    const formattedAllTests = formatOrder([
      ...formattedData.testGroupList,
      ...(formattedData.customTestGroup.length
        ? [
            {
              name: t('CREATE_SCREENING_CUSTOM_TEST_GROUP_TITLE'),
              type: TestGroupTypes.CUSTOM,
              order: data?.customTestGroup?.order,
              weight: data?.customTestGroup?.weight,
              duration: formattedData.customScreeningTGDuration,
              tests: formattedData.customTestGroup,
              hasSkipQuestion: formattedData.customTestGroupHasSkipQuestion,
              id: v4(),
            },
          ]
        : []),
    ]);
    dispatch(
      setNewScreeningData({
        ...newScreening,
        testGroups: formattedData.testGroupList.map((testGroup) => ({
          ...testGroup,
        })),
        customTestGroupHasSkipQuestion: formattedData.customTestGroupHasSkipQuestion,
        customTestGroup: formattedData.customTestGroup,
        customScreeningTGDuration: formattedData.customScreeningTGDuration,
        allTests: formattedAllTests,
        prefilterQuestions: formattedData?.prefilterQuestions,
        prefilterQuestionsDelay: formattedData?.prefilterQuestionsDelay,
        welcomeVideoUrl: formattedData.welcomeVideoUrl,
        welcomeVideoTitle: formattedData.welcomeVideoTitle,
      })
    );
    setPreviousData(formattedData);
    SetPreviousWeightsAndOrders(
      formattedAllTests.map((item) => ({
        [item.id]: {
          weight: item.weight,
          order: item.order,
        },
      }))
    );
  };

  useEffect(() => {
    setEnableCreateButton(
      evaluation.name.trim().length &&
        ((quickTest && !isNullOrUndefined(evaluation.quickStartRoleId)) || !quickTest)
    );
  }, [evaluation, quickTest]);

  useEffect(() => {
    setPreventReload((existingChanges && !showExitModal) || Boolean(incompleteQuestionValidation));
  }, [existingChanges, showExitModal, incompleteQuestionValidation]);

  useEffect(() => {
    if (queryData.draftId && !evaluation.name.length) {
      setLoading(true);
      getRecruitSessionDraft(queryData?.draftId).then((res) => {
        setFormattedData(res);
        setLoading(false);
      });
    }
  }, [queryData?.draftId]);

  const handleClearData = (filters = true) => {
    dispatch(
      setEvaluationData({
        name: '',
        startDate: {
          day: new Date().toISOString(),
          hours: '00:00',
        },
        endDate: {
          day: new Date('9999-01-01').toISOString(),
          hours: '00:00',
        },
        allowCertificateDownload: true,
        proctoringEnabled: true,
        requireCompletedProfile: false,
        reminderToCandidates: true,
        reviewType: ReviewTypeEnum.GLOBAL,
        emptyDates: true,
      })
    );
    dispatch(
      setNewScreeningData({
        ...newScreening,
        testGroups: [],
        customTestGroup: [],
        prefilterQuestionsDelay: undefined,
        prefilterQuestions: [],
        customScreeningTGDuration: '0 min',
        welcomeVideoUrl: undefined,
        welcomeVideoTitle: undefined,
      })
    );
    if (filters) {
      handleClearFilters();
    }
  };

  const handleEvaluationName = (e) => {
    if (evaluation.name.length <= 50) {
      dispatch(
        setEvaluationData({
          ...evaluation,
          name: e.target.value,
        })
      );
    }
  };

  const handleEvaluationQuickRole = (id) => {
    if (id) {
      dispatch(
        setEvaluationData({
          ...evaluation,
          quickStartRoleId: id,
        })
      );
    }
  };

  const handleSetEvaluationData = (data) => {
    dispatch(setEvaluationData(data));
  };

  const formatDateWithObject = (day, hour) => {
    const date = moment(day);
    date.set({ hours: 0, minutes: 0, seconds: 0 });
    date.add(hour).utc();
    return date.toDate();
  };

  const handleCreateDraft = async () => {
    setLoadingCreateDraft(true);
    setPreventReload(false);
    const postData = {
      name: evaluation.name,
      startDate: formatDateWithObject(evaluation.startDate.day, 0),
      endDate: formatDateWithObject(evaluation.endDate.day, 0),
      companyId: getLocalStorageWorkspace()?.companyId,
      workspaceId: currentWorkspace,
      purpose: null,
      customPurpose: null,
      allowCertificateDownload: evaluation.allowCertificateDownload ?? true,
      proctoringEnabled: evaluation.proctoringEnabled ?? true,
      exclusiveUsers: evaluation.exclusiveUsers ?? false,
      requireCompletedProfile: evaluation.requireCompletedProfile ?? false,
      reminderToCandidates: evaluation.reminderToCandidates ?? true,
      reviewType: evaluation.reviewType ?? ReviewTypeEnum.GLOBAL,
      quickStartRoleId: evaluation.quickStartRoleId,
      roleId: evaluation.roleId,
      customRole: evaluation.customRole,
      emptyDates: true,
    };
    if (
      formatDateWithObject(evaluation?.startDate?.day, evaluation?.startDate?.hours) >=
      formatDateWithObject(evaluation?.endDate?.day, evaluation?.endDate?.hours)
    ) {
      notification.open({
        message: t('CREATE_SEARCH_STAGE_END_DATE_BEFORE_START_DATE'),
        type: 'error',
      });
      setLoadingCreateDraft(false);
    } else {
      TrackEvent('create-draft');
      await postRecruitSessionDraft(postData)
        .then((res) => {
          history.replace({ search: `draftId=${res.id}` });
          setFormattedData(res);

          if (!isNullOrUndefined(evaluation.quickStartRoleId)) {
            getRecruitSessionDraft(res.id)
              .then((res) => {
                setFormattedData(res);
                setStep(3);
              })
              .catch(() => {
                setStep(1);
              });
          } else {
            setStep(1);
          }
          TrackEvent('evaluation-nav-p2');
          notification.open({
            message: t('CREATE_EVALUATION_DRAFT_CREATED_NOTIFICATION'),
            type: 'success',
          });
        })
        .finally(() => {
          setLoadingCreateDraft(false);
        });
    }
  };

  const handleEditDraft = async () => {
    setPreventReload(false);
    const customTestGroup = newScreening?.allTests?.find(
      (item) => item.type === TestGroupTypes.CUSTOM
    );
    const patchData = {
      name: evaluation.name,
      startDate: formatDateWithObject(evaluation.startDate.day, evaluation.startDate.hours),
      endDate: formatDateWithObject(evaluation.endDate.day, evaluation.endDate.hours),
      allowCertificateDownload: evaluation.allowCertificateDownload ?? true,
      proctoringEnabled: evaluation.proctoringEnabled ?? true,
      exclusiveUsers: evaluation.exclusiveUsers ?? false,
      requireCompletedProfile: evaluation.requireCompletedProfile ?? false,
      reminderToCandidates: evaluation.reminderToCandidates ?? true,
      reviewType: evaluation.reviewType ?? ReviewTypeEnum.GLOBAL,
      testGroupList:
        newScreening?.allTests?.filter((item) => item.type !== TestGroupTypes.CUSTOM) ?? [],
      customTestGroup: validCustomTestGroup()
        ? {
            weight: newScreening?.allTests?.find((item) => item.type === TestGroupTypes.CUSTOM)
              ?.weight,
            order: newScreening?.allTests?.find((item) => item.type === TestGroupTypes.CUSTOM)
              ?.order,
            duration: customScreeningTotalTime,
            tests: newScreening?.customTestGroup,
            hasSkipQuestion: customTestGroup?.hasSkipQuestion ?? true,
          }
        : {},
      purpose: evaluation.purpose,
      customPurpose: evaluation.customPurpose,
      emptyDates: evaluation.emptyDates,
      quickStartRoleId: quickTest ? evaluation.quickStartRoleId : null,
      roleId: evaluation.roleId,
      workspaceId: currentWorkspace,
      ...(newScreening?.prefilterQuestions?.length > 0
        ? {
            prefilterQuestionsDraft: {
              name: evaluation.name,
              workspaceId: currentWorkspace,
              companyId: user?.companyId,
              negativeDelay: newScreening.prefilterQuestionsDelay,
              type: 'PRE_FILTER',
              testGroups: [
                {
                  tests: newScreening.prefilterQuestions,
                  name: evaluation.name,
                  type: 'PRE_FILTER',
                  workspaceId: currentWorkspace,
                  companyId: user?.companyId,
                },
              ],
            },
          }
        : {}),
      welcomeVideoUrl: newScreening.welcomeVideoUrl,
      welcomeVideoTitle: newScreening.welcomeVideoTitle,
    };

    await editRecruitSessionDraft(queryData?.draftId, patchData).then((res) => {
      setFormattedData(res);
      notification.open({
        message: t('CREATE_EVALUATION_DRAFT_EDITED_NOTIFICATION'),
        type: 'success',
      });
    });

    if (showExitModal) {
      setShowExitModal(false);
    }
  };

  const determineNotification = () => {
    if (!evaluation.name) {
      notification.open({
        message: t('CREATE_EVALUATION_NAME_REQUIRED_TEXT'),
        type: 'error',
      });
      return;
    }
    if (!evaluation.startDate.day || !evaluation.endDate.day) {
      notification.open({
        message: t('CREATE_EVALUATION_START_AND_END_DATES_REQUIRED_TEXT'),
        type: 'error',
      });
      return;
    }
    if (!validCustomTestGroup()) {
      if (!parsedCustomTGDuration) {
        notification.open({
          message: t('CREATE_EVALUATION_CUSTOM_TESTGROUP_TIME_REQUIRED_TEXT'),
          type: 'error',
        });
      }
      return;
    }
    if (!newScreening.allTests.length) {
      notification.open({
        message: t('CREATE_EVALUATION_AT_LEAST_ONE_TEST_OR_QUESTION_REQUIRED_TEXT'),
        type: 'error',
      });
      return;
    }
    if (availableWeight < 0) {
      notification.open({
        message: t('CREATE_EVALUATION_EXCEEDED_TESTS_WEIGHT_TEXT'),
        type: 'error',
      });
      return;
    }
    if (availableWeight > 0) {
      notification.open({
        message: t('CREATE_EVALUATION_REMAINING_TESTS_WEIGHT_TEXT'),
        type: 'error',
      });
      return;
    }
    if (missingTestgroupWeight) {
      notification.open({
        message: t('CREATE_EVALUATION_MISSING_SOME_TEST_WEIGHT_TEXT'),
        type: 'error',
      });
    }
  };

  const handleOpenEvaluation = async () => {
    setLoadingCreateEvaluation(true);
    const customTestGroup = newScreening?.allTests?.find(
      (item) => item.type === TestGroupTypes.CUSTOM
    );
    const formattedCustomTestGroup = formatCustomTestGroupToPost(customTestGroup);
    const alkemyTestGroups =
      newScreening?.allTests?.filter((item) => item.type !== TestGroupTypes.CUSTOM) ?? [];
    const formattedTestGroups = formatOrder([...alkemyTestGroups, formattedCustomTestGroup]).map(
      (item) => ({
        ...item,
        weight: item.weight ?? 0,
        tests: item.tests.map((test) => ({
          ...test,
          companyId: getLocalStorageWorkspace()?.companyId,
        })),
      })
    );
    const dataToPost = {
      id: queryData.draftId,
      name: evaluation.name,
      companyId: getLocalStorageWorkspace()?.companyId,
      userId: user?.id,
      duration: customScreeningTotalTime + testsDuration,
      startDate: formatDateWithObject(evaluation.startDate.day, evaluation.startDate.hours),
      endDate: formatDateWithObject(evaluation.endDate.day, evaluation.endDate.hours),
      testGroups: formattedTestGroups,
      workspaceId: currentWorkspace,
      purpose: evaluation.purpose ?? 'OTHER',
      customPurpose: evaluation.customPurpose,
      allowCertificateDownload: evaluation.allowCertificateDownload ?? true,
      proctoringEnabled: evaluation.proctoringEnabled ?? true,
      requireCompletedProfile: evaluation.requireCompletedProfile ?? false,
      reminderToCandidates: evaluation.reminderToCandidates ?? true,
      reviewType: evaluation.reviewType ?? ReviewTypeEnum.GLOBAL,
      emptyDates: evaluation.emptyDates,
      exclusiveUsers: evaluation.exclusiveUsers ?? false,
      prefilterQuestionsDraft: evaluation?.prefilterQuestions,
      welcomeVideoUrl: newScreening.welcomeVideoUrl,
      welcomeVideoTitle: newScreening.welcomeVideoTitle,
    };
    if (disableOpenEvaluation) {
      determineNotification();
    } else {
      TrackEvent('open-evaluation');
      setPreventReload(false);
      await openEvaluation(dataToPost).then((res) => {
        if (Object.keys(res)) {
          notification.open({
            message: t('SEARCH_CREATED_SUCCESSFULLY'),
            type: 'success',
          });
          dispatch(
            setNewSearchData({
              ...newSearch,
              name: res.name,
              step: newSearch.step + 1,
              stages: res.stages,
            })
          );
          dispatch(setSearchInvitationsData({}));
          setLoading(false);
          dispatch(setSendEmailFrom('new'));
          history.push('/createsearch/success');
        } else {
          notification.open({
            message: t('SEARCH_CREATE_ERROR'),
            type: 'error',
          });
        }
        setLoadingCreateEvaluation(false);
      });
    }
  };

  const fetchTests = async ({ rolesIds, favorites, persistFilters = false }) => {
    const isEvaluationPage = history.location.pathname.includes(CREATE_EVALUATION);
    setLoadingTests(true);
    const formattedFilters = formatFilters(newScreeningFilters);
    await getScreeningTestGroups(
      persistFilters
        ? {
            ...formattedFilters,
            rolesIds,
            favorites,
            ...(isEvaluationPage ? { recommendTestGroups: true } : {}),
          }
        : { rolesIds, favorites, ...(isEvaluationPage ? { recommendTestGroups: true } : {}) }
    ).then((res) => {
      dispatch(setTestGroupsData(res));
      setLoadingTests(false);
    });
  };

  const handleChangeStep = async (nextStep) => {
    TrackEvent(`evaluation-nav-p${nextStep + 1}`);
    if (step === 0) {
      if (quickTest && isNullOrUndefined(evaluation.quickStartRoleId)) {
        notification.open({
          message: t('CREATE_SEARCH_STAGE_QUICK_ROLE_REQUIRED_TEXT'),
          type: 'error',
        });
        return;
      }
      if (!evaluation.name) {
        determineNotification();
        return;
      }
    }
    if (step === 2) {
      if (incompleteQuestionValidation) {
        notification.open({
          message: t('CREATE_CUSTOM_TEST_INCOMPLETE_TEST_FORM_ERROR'),
          type: 'error',
        });
        dispatch(setCustomTestGroupIncompleteQuestion(true));
        return;
      }
      if (incompletePrefilterQuestionValidation) {
        notification.open({
          message: t('CREATE_CUSTOM_PREFILTER_INCOMPLETE_FORM_ERROR'),
          type: 'error',
        });
        return;
      }
      if (!validCustomTestGroup()) {
        determineNotification();
        return;
      }
    }
    if (existingChanges) {
      try {
        await handleEditDraft();
      } catch (e) {
        notification.open({
          message: t('ERROR_ON_CREATE_DRAFT'),
          type: 'error',
        });
        return;
      }
    }
    setStep(nextStep);
  };

  const saveChangesModal = () => (
    <Modal
      closeBtnClassname="font-weight-bold text-muted"
      show={showExitModal}
      minWidth={false}
      onClose={() => {
        setShowExitModal(false);
      }}
      setShow={(value) => {
        setShowExitModal(value);
      }}
      className="create-evaluation-custom-modal"
    >
      <div className="mt-5 d-flex flex-column justify-content-center align-items-center pt-1 pb-4">
        <CreateEvaluationModalImage />
        <span className="font-montserrat text-base font-weight-600 py-3">
          {t('CREATE_EVALUATION_SAVE_CHANGES_MODAL_TEXT')}
        </span>
        <div className="w-75 d-flex justify-content-between align-items-center">
          <button
            type="button"
            className="border-0 bg-transparent text-maya-blue text-xs font-weight-bold font-montserrat"
            onClick={() => {
              if (showExitModal) {
                setShowExitModal(false);
                history.push(SCREENING_SEARCHES);
              }
            }}
          >
            {t('CREATE_EVALUATION_SAVE_CHANGES_DONT_SAVE_BUTTON')}
          </button>
          <button
            onClick={async () => {
              if (queryData?.draftId) {
                if (
                  formatDateWithObject(evaluation?.startDate?.day, evaluation?.startDate?.hours) >=
                  formatDateWithObject(evaluation?.endDate?.day, evaluation?.endDate?.hours)
                ) {
                  notification.open({
                    message: t('CREATE_SEARCH_STAGE_END_DATE_BEFORE_START_DATE'),
                    type: 'error',
                  });
                  setShowExitModal(false);
                  return;
                }
                if (!validCustomTestGroup()) {
                  determineNotification();
                  setShowExitModal(false);
                  return;
                }
                if (incompleteQuestionValidation) {
                  setShowExitModal(false);
                  notification.open({
                    message: t('CREATE_CUSTOM_TEST_INCOMPLETE_TEST_FORM_ERROR'),
                    type: 'error',
                  });
                  dispatch(setCustomTestGroupIncompleteQuestion(true));
                  return;
                }
                if (incompletePrefilterQuestionValidation) {
                  notification.open({
                    message: t('CREATE_CUSTOM_PREFILTER_INCOMPLETE_FORM_ERROR'),
                    type: 'error',
                  });
                  return;
                }
                await handleEditDraft();
              } else {
                if (!evaluation.name) {
                  determineNotification();
                  setShowExitModal(false);
                  return;
                }
                await handleCreateDraft();
              }
              if (showExitModal) {
                setShowExitModal(false);
                history.push(SCREENING_SEARCHES);
              }
            }}
            className="align-self-end px-3 py-2 rounded-pill font-montserrat text-xs text-white font-weight-bold border-0 outline-none bg-maya-blue"
            type="button"
          >
            {t('CREATE_EVALUATION_SAVE_CHANGES_SAVE_BUTTON')}
          </button>
        </div>
      </div>
    </Modal>
  );

  return {
    step,
    draftId: queryData?.draftId,
    showExitModal,
    enableCreateButton,
    existingChanges,
    evaluation,
    loading,
    loadingTests,
    disableEditButton,
    disableOpenEvaluation,
    disableCustomTestGroupWeight,
    missingTestgroupWeight,
    preventReload,
    incompleteQuestionValidation,
    EvaluationPurposesOptions,
    fetchTests,
    saveChangesModal,
    setStep,
    setLoadingTests,
    setShowExitModal,
    handleEvaluationName,
    handleEvaluationQuickRole,
    handleClearData,
    handleSetEvaluationData,
    handleCreateDraft,
    handleOpenEvaluation,
    handleEditDraft,
    handleChangeStep,
    loadingCreateDraft,
    quickTest,
    setQuickTest,
    loadingCreateEvaluation,
  };
};

export default useCreateEvaluation;
