import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getScreeningDetails } from '../API/services/screeningService';
import { setModalData } from '../redux/actions/uxActions';
import Spinner from '../components/spinner/Spinner';
import { formatMinutesToHours } from '../utils/formatMinutesToHours';
import TestGroupsListItem from '../components/TestGroupsListItem/TestGroupsListItem';
import { t } from '../i18n/i18n';
import { getLocalStorageWorkspace } from '../utils/workspaceUtils';

const useModalDetails = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const { modalData } = useSelector((state) => state.ux);

  const closeModal = (e) => {
    setShowModal(e);
    setLoadingModal(true);
    if (document.getElementById('custom-modal')) {
      document.getElementById('custom-modal').style.minHeight = '200px';
    }
    dispatch(setModalData(undefined));
  };

  const handleScreeningModalData = async (screeningId) => {
    setShowModal(true);
    await getScreeningDetails(screeningId)
      .then((res) => {
        dispatch(setModalData(res));
      })
      .catch(() => {
        document.getElementById('custom-modal').style.minHeight = '130px';
        dispatch(setModalData({ err: true }));
      })
      .finally(() => setLoadingModal(false));
  };

  const screeningModalContent = () =>
    loadingModal ? (
      <Spinner show />
    ) : (
      <>
        {modalData?.err ? (
          <div className="overflow-auto w-100">
            <p className="font-montserrat text-center font-weight-500 m-0">
              {t('SCREENING_MODAL_CANNOT_GET_SCREENING_DATA')}
            </p>
          </div>
        ) : (
          <div data-testid="modal-detail-tests" className="p-2 overflow-auto w-100">
            <span className="text-normal font-montserrat font-weight-bold">{modalData.name}</span>
            <div className="d-flex mt-3">
              <div className="my-1 my-sm-0 ml-0">
                <span className="font-montserrat font-italic text-muted text-sm font-weight-bold">
                  {t('CREATE_SCREENING_SELECTED_TESTS')}
                </span>
                <span className="total-number-text font-italic font-montserrat font-weight-bold ml-1">
                  {modalData.testGroups.length}
                </span>
              </div>
              <div className="my-1 my-sm-0 ml-0 ml-sm-2 ml-md-5">
                <span className="font-montserrat font-italic text-muted text-sm font-weight-bold">
                  {t('CREATE_SCREENING_TOTAL_TIME')}
                </span>
                <span className="total-number-text font-italic font-montserrat font-weight-bold ml-1">
                  {formatMinutesToHours(
                    modalData.testGroups.reduce((acum, curr) => acum + (curr?.duration ?? 0), 0)
                  )}
                </span>
              </div>
            </div>
            {modalData.testGroups.map((testGroup) => {
              const formattedTestGroup = {
                ...testGroup,
                description:
                  testGroup?.category?.id === process.env.RAZZLE_RUNTIME_CATEGORY_ID
                    ? t('CUSTOM_TESTGROUP_DETAILS_DESCRIPTION').replace(
                        '{companyName}',
                        getLocalStorageWorkspace()?.companyName
                      )
                    : testGroup.description,
              };
              return (
                <TestGroupsListItem
                  disableCustomWeight
                  disableQuestionIcon
                  hideOrderArrows
                  hideDeleteIcon
                  testGroup={formattedTestGroup}
                  weightCustomWidth
                />
              );
            })}
          </div>
        )}
      </>
    );

  const handleShowModal = () => {
    setShowModal(true);
  };

  return {
    showModal,
    loadingModal,
    modalData,
    closeModal,
    handleShowModal,
    handleScreeningModalData,
    screeningModalContent,
  };
};

export default useModalDetails;
