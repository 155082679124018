import { defaultSearchTableHeaders } from '../../constants/searchTablePersonalization';
import {
  SET_SCREENINGS,
  SET_SCREENING_TECHNOLOGIES,
  SET_SCREENING_INVITATIONS,
  SET_SCREENING_INVITATIONS_FILTERS,
  SET_NEW_SEARCH_DATA,
  SET_SELECTED,
  SET_CONFIRMED,
  SET_SEARCHES,
  SET_TEMPLATE,
  SET_SUBJECT_EMAIL,
  SET_SELECTED_SEARCH,
  SET_SELECTED_SEARCH_FILTERS,
  SET_STAGE_CANDIDATES,
  SET_EMAILS_DATA,
  SET_NEW_SCREENING_DATA,
  SET_NEW_SCREENING_FILTERS,
  SET_NEW_SCREENING_LEVELS_FILTERS_OPTIONS,
  SET_NEW_SCREENING_CATEGORIES_FILTERS_OPTIONS,
  SET_TEST_GROUPS_DATA,
  SET_SUBMISSIONS_SELECTED,
  SET_SEARCH_WAS_ALREADY_CREATED,
  SET_SEARCH_INVITATIONS_LEFT,
  SET_SEND_EMAIL_FROM,
  SET_CREATE_SCREENING_FROM_SEARCH,
  SET_AMOUNT_OF_FILTERS,
  SET_NEW_SCREENING_ROLES_FILTERS_OPTIONS,
  SET_KEEP_SEARCH_DATA,
  SET_INVITATION_ID_TO_SCROLL,
  SET_SCREENING_ID_TO_KEEP,
  SET_DISPLAY_TEST_GROUP,
  SET_CUSTOM_TESTGROUP_INCOMPLETE_QUESTION,
  SET_SEARCH_TABLE_PERSONALIZATION,
  SET_STAGE_SCREENING,
  SET_NEW_SCREENING_QUICK_ROLES_FILTERS_OPTIONS,
  SET_NEW_SCREENING_TAGS_FILTERS_OPTIONS,
  SET_ENDORSEMENT_CREATOR_OPTIONS,
  SET_ENDORSEMENT_COMPANY_OPTIONS,
} from '../actionTypes';

const initialState = {
  technologies: [],
  screenings: [],
  screening: null,
  nextUrl: null,
  loading: false,
  toEdit: {
    id: null,
  },
  screeningInvitations: null,
  screeningInvitationsFilters: {},
  newSearch: {
    step: 1,
    name: '',
    stagesQuantity: 1,
    stages: [],
  },
  sendEmailFrom: '',
  selected: null,
  confirmed: false,
  searches: {
    collection: [],
    pagination: {},
  },
  template: {
    id: null,
    html: '',
  },
  subjectEmail: '',
  newScreening: {
    step: 1,
    name: '',
    testGroups: [],
    customTestGroup: [],
    prefilterQuestions: [],
    prefilterQuestionsDelay: undefined,
    prefilterQuestionsCompanyOptions: undefined,
    customTestGroupHasSkipQuestion: undefined,
    customTestGroupDuration: {
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
    },
    customScreeningTGDuration: '0 min',
    availableWeight: 100,
    isEdit: false,
    eachScore: false,
    currentTypeQuestion: 'CHECK',
    currentQuestionData: undefined,
    allTests: [],
    deleteTests: [],
    welcomeVideoTitle: undefined,
    welcomeVideoUrl: undefined,
  },
  customTestGroupIncompleteQuestion: false,
  newScreeningFilters: {
    category: [],
    levelId: [],
    name: '',
    rolesIds: [],
    selectedRole: {},
  },
  testGroupLevelsFilterOptions: [],
  testGroupCategoriesFilterOptions: [],
  testGroupRolesFilterOptions: [],
  testGroupTagsFilterOptions: [],
  endorsementCreatorsOptions: [],
  endorsementCompaniesOptions: [],
  testGroupQuickRolesOptions: [],
  testGroups: {},
  selectedSearch: {},
  emailsData: {},
  stageCandidates: null,
  createScreeningFromSearches: false,
  searchInvitations: {
    invitationsLeft: 300,
    invitationsUsed: 0,
    recruitSessionStageId: undefined,
    invitationPublicLink: '',
  },
  selectedSearchFilters: {},
  submissionSelected: {},
  searchWasAlreadyCreated: false,
  amountOfFilters: 0,
  keepSearchData: false,
  invitationIdToScroll: undefined,
  screeningIdToKeep: undefined,
  displayTestgroups: true,
  searchTablePersonalization: defaultSearchTableHeaders,
  stageScreening: null,
};

function screeningsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SCREENINGS: {
      return { ...state, ...action.payload };
    }
    case SET_SCREENING_TECHNOLOGIES: {
      return { ...state, ...action.payload };
    }
    case SET_SCREENING_INVITATIONS: {
      return { ...state, ...action.payload };
    }
    case SET_SCREENING_INVITATIONS_FILTERS: {
      return { ...state, ...action.payload };
    }
    case SET_NEW_SEARCH_DATA: {
      return { ...state, ...action.payload };
    }
    case SET_SELECTED: {
      return { ...state, ...action.payload };
    }
    case SET_CONFIRMED: {
      return { ...state, ...action.payload };
    }
    case SET_SEARCHES: {
      return { ...state, ...action.payload };
    }
    case SET_TEMPLATE: {
      return { ...state, ...action.payload };
    }
    case SET_SUBJECT_EMAIL: {
      return { ...state, ...action.payload };
    }
    case SET_SELECTED_SEARCH: {
      return { ...state, ...action.payload };
    }
    case SET_SELECTED_SEARCH_FILTERS: {
      return { ...state, ...action.payload };
    }
    case SET_STAGE_CANDIDATES: {
      return { ...state, ...action.payload };
    }
    case SET_EMAILS_DATA: {
      return { ...state, ...action.payload };
    }
    case SET_NEW_SCREENING_DATA: {
      return { ...state, ...action.payload };
    }
    case SET_NEW_SCREENING_FILTERS: {
      return { ...state, ...action.payload };
    }
    case SET_NEW_SCREENING_LEVELS_FILTERS_OPTIONS: {
      return { ...state, ...action.payload };
    }
    case SET_NEW_SCREENING_CATEGORIES_FILTERS_OPTIONS: {
      return { ...state, ...action.payload };
    }
    case SET_NEW_SCREENING_ROLES_FILTERS_OPTIONS: {
      return { ...state, ...action.payload };
    }
    case SET_NEW_SCREENING_TAGS_FILTERS_OPTIONS: {
      return { ...state, ...action.payload };
    }
    case SET_ENDORSEMENT_CREATOR_OPTIONS: {
      return { ...state, ...action.payload };
    }
    case SET_ENDORSEMENT_COMPANY_OPTIONS: {
      return { ...state, ...action.payload };
    }
    case SET_NEW_SCREENING_QUICK_ROLES_FILTERS_OPTIONS: {
      return { ...state, ...action.payload };
    }
    case SET_TEST_GROUPS_DATA: {
      return { ...state, ...action.payload };
    }
    case SET_SUBMISSIONS_SELECTED: {
      return { ...state, ...action.payload };
    }
    case SET_SEARCH_WAS_ALREADY_CREATED: {
      return { ...state, searchWasAlreadyCreated: action.payload };
    }
    case SET_SEARCH_INVITATIONS_LEFT: {
      return { ...state, searchInvitations: action.payload };
    }
    case SET_SEND_EMAIL_FROM: {
      return { ...state, sendEmailFrom: action.payload };
    }
    case SET_CREATE_SCREENING_FROM_SEARCH: {
      return { ...state, ...action.payload };
    }
    case SET_AMOUNT_OF_FILTERS: {
      return { ...state, ...action.payload };
    }
    case SET_KEEP_SEARCH_DATA: {
      return { ...state, ...action.payload };
    }
    case SET_INVITATION_ID_TO_SCROLL: {
      return { ...state, ...action.payload };
    }
    case SET_SCREENING_ID_TO_KEEP: {
      return { ...state, ...action.payload };
    }
    case SET_DISPLAY_TEST_GROUP: {
      return { ...state, ...action.payload };
    }
    case SET_CUSTOM_TESTGROUP_INCOMPLETE_QUESTION: {
      return { ...state, ...action.payload };
    }
    case SET_SEARCH_TABLE_PERSONALIZATION: {
      return { ...state, ...action.payload };
    }
    case SET_STAGE_SCREENING: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export default screeningsReducer;
