export const HiredStatus = {
  AVAILABLE: 'AVAILABLE',
  SELECTED: 'SELECTED',
  HIRED: 'HIRED',
  HIRED_THIRDPARTY: 'HIRED_THIRDPARTY',
  PREHIRED: 'PREHIRED',
  LEAVER: 'LEAVER',
};

export const PartnerEnvironment = {
  GENERAL: 'GENERAL',
  MY_COMPANY: 'MY_COMPANY',
};

export const Orders = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const ScreeningInvitationStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  INVITED: 'INVITED',
  UNSTARTED: 'UNSTARTED',
  PROCESSING: 'PROCESSING',
  EXPIRED: 'EXPIRED',
  PREFILTERING: 'PREFILTERING',
  PREFILTERED: 'PREFILTERED',
};

export const SearchDashboardColumns = {
  PROFILE: 'PROFILE',
  SCORE: 'SCORE',
  TOTALEVENTS: 'TOTALEVENTS',
  COUNTRY: 'COUNTRY',
  CITY: 'CITY',
  PROCESS_STATUS: 'PROCESS_STATUS',
  COMPLETE: 'COMPLETE',
  SCREENINGS_STATUS: 'SCREENINGS_STATUS',
  LIMIT_DATE: 'LIMIT_DATE',
};

export const Auth0Error = {
  VALIDATE_EMAIL: 'VALIDATE_EMAIL',
  LINKING_ACCOUNTS: 'LINKING_ACCOUNTS',
  USER_EXISTS: 'USER_EXISTS',
  USER_EXISTS_GOOGLE: 'USER_EXISTS_GOOGLE',
  USER_EXISTS_PASSWORD: 'USER_EXISTS_PASSWORD',
  EMAIL_COMPANY_MAIL_REQUIRED: 'EMAIL_COMPANY_MAIL_REQUIRED',
};

export const ProcessStatus = {
  SCREENING: 'SCREENING',
  INTERVIEW: 'INTERVIEW',
  FINALIST: 'FINALIST',
  HIRED: 'HIRED',
  REJECTED: 'REJECTED',
};

export const ProcessStatusStage = {
  NOT_RATED: 'NOT_RATED',
  EVALUATED: 'EVALUATED',
  INTERVIEW: 'INTERVIEW',
  INTERVIEWED: 'INTERVIEWED',
  FINALIST: 'FINALIST',
  OFFER_SEND: 'OFFER_SEND',
  OFFER_REJECTED: 'OFFER_REJECTED',
  NOT_RESPONSE: 'NOT_RESPONSE',
  REJECTED: 'REJECTED',
  HIRED: 'HIRED',
};

export const ProcessStatusStageColor = {
  NOT_RATED: 'invited-dot-bg',
  EVALUATED: 'invited-dot-bg',
  INTERVIEW: 'in-progress-dot-bg',
  INTERVIEWED: 'in-progress-dot-bg',
  FINALIST: 'in-progress-dot-bg',
  OFFER_SEND: 'completed-dot-bg',
  OFFER_REJECTED: 'bg-danger',
  NOT_RESPONSE: 'bg-danger',
  REJECTED: 'bg-danger',
  HIRED: 'completed-dot-bg',
};

export const AnswerTypeEnum = {
  RADIO: 'RADIO',
  CHECK: 'CHECK',
  WRITE: 'WRITE',
  TEXT: 'TEXT',
  TEXT_AI: 'TEXT_AI',
  FILE: 'FILE',
  VIDEO: 'VIDEO',
  CODE: 'CODE',
};

export const MechanografyTypeEnum = {
  NO_MECHANOGRAFY: 'NO_MECHANOGRAFY',
  MECHANOGRAFY: 'MECHANOGRAFY',
};

export const FilesType = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  CSV: 'CSV',
  PDF: 'PDF',
  TEXT: 'TEXT',
};

export const TestEditionAction = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

export const ErrorType = {
  INVALID_ROLES: 'INVALID_ROLES',
};

export const ContentToDisplayEnum = {
  DASHBOARD: 'DASHBOARD',
  STATS: 'STATS',
};

export const TestFileType = {
  IMAGE: 'IMAGE',
  AUDIO: 'AUDIO',
};

export const TestGroupTypes = {
  QUALITATIVE: 'QUALITATIVE',
  QUANTITATIVE: 'QUANTITATIVE',
  CUSTOM: 'custom',
  PRE_FILTER: 'PRE_FILTER',
};

export const PartnerWorkspaceRole = {
  OWNER: 'OWNER',
  ADMINISTRATOR: 'ADMINISTRATOR',
  VISUALIZATOR: 'VISUALIZATOR',
};

export const PartnerToWorkspaceStatus = {
  WAITING_ACCEPTANCE: 'WAITING_ACCEPTANCE',
  WAITING_APPROVAL: 'WAITING_APPROVAL',
};

export const EvaluationPurposes = {
  TEST_THE_FEATURE: 'TEST_THE_FEATURE',
  INTERN_PERSONAL_MAPPING: 'INTERN_PERSONAL_MAPPING',
  EXTERNAL_PERSONAL_SEARCH: 'EXTERNAL_PERSONAL_SEARCH',
  INTERNAL_PERSONAL_SEARCH: 'INTERNAL_PERSONAL_SEARCH',
  MEASURE_SKILLS_FOR_TRAINING_PROGRAM: 'MEASURE_SKILLS_FOR_TRAINING_PROGRAM',
  OTHER: 'OTHER',
};

export const PlanFeaturesIds = {
  SCREENINGS: 1,
  MARKETPLACE: 2,
  PROCTORING: 3,
  SEARCH_LINKS: 4,
  SEARCH_STATS: 5,
  CUSTOM_DATES: 6,
  TEXT_AI: 7,
  MAX_INVITATIONS: 8,
  WORKSPACE_MEMBERS: 9,
  VIDEO_ANSWERS: 15,
  EXCLUSIVE_TEMP_USERS: 16,
  JOB_POSTING: 17,
};

export const PlanType = {
  PROJECT: 'PROJECT',
  SUBSCRIPTION: 'SUBSCRIPTION',
};

export const Modality = {
  PARTTIME: 'PART_TIME',
  FULLTIME: 'FULL_TIME',
  VOLUNTEER: 'VOLUNTEER',
  CONTRACTOR: 'CONTRACTOR',
  PER_HOUR: 'PER_HOUR',
  INTERNSHIP: 'INTERNSHIP',
  NIGHTLY: 'NIGHTLY',
};

export const WorkModality = {
  REMOTE: 'REMOTE',
  HIBRID: 'HIBRID',
  ON_SITE: 'ON_SITE',
};

export const Seniority = {
  TRAINEE: 'TRAINEE',
  JUNIOR: 'JUNIOR',
  SEMI_SENIOR: 'SEMI_SENIOR',
  SENIOR: 'SENIOR',
  TECH_LEAD: 'TECH_LEAD',
};

export const JobPostRequiredFields = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  INDUSTRY: 'studyAreaId',
  MODALITY: 'modality',
  WORKMODALITY: 'workModality',
  ROLEID: 'roleId',
  SCREENINGS: 'screeningUrl',
  COUNTRY: 'locationsToOffer',
};

export const Currencies = {
  ARS: 'ARS',
  USD: 'USD',
  CLP: 'CLP',
  EUR: 'EUR',
};

export const TimeFrequency = {
  DAILY: 'DAILY"',
  WEEKLY: 'WEEKLY',
  SEMI_MONTHLY: 'SEMI_MONTHLY',
  MONTHLY: 'MONTHLY',
  ANNUAL: 'ANNUAL',
};

export const DifficultyTag = {
  VERY_EASY: 'VERY_EASY',
  EASY: 'EASY',
  INTERMEDIATE: 'INTERMEDIATE',
  HARD: 'HARD',
  VERY_HARD: 'VERY_HARD',
  ADVANCED: 'ADVANCED',
};

export const languagesKey = {
  SQL: 'sql',
  NODEJS: 'nodejs',
  JAVA: 'java',
  RUBY: 'ruby',
  PYTHON_3: 'python3',
  PYTHON_2: 'python2',
  CSHARP: 'csharp',
  SWIFT: 'swift',
  KOTLIN: 'kotlin',
};

export const languagesToImplement = [
  { id: languagesKey.SQL, displayName: 'SQL' },
  { id: languagesKey.NODEJS, displayName: 'JavaScript' },
  { id: languagesKey.JAVA, displayName: 'JAVA' },
  { id: languagesKey.RUBY, displayName: 'RUBY' },
  { id: languagesKey.PYTHON_3, displayName: 'Python 3' },
  { id: languagesKey.PYTHON_2, displayName: 'Python 2' },
  { id: languagesKey.CSHARP, displayName: 'C#' },
  { id: languagesKey.SWIFT, displayName: 'Swift' },
  { id: languagesKey.KOTLIN, displayName: 'Kotlin' },
];

export const Countries = {
  ARGENTINA: 'ARG',
};

export const Regions = {
  ARGENTINA: 'es-AR',
  USA: 'en-US',
};
export const TAGS_TYPE = {
  LEVEL: 'LEVEL',
  MARKETING: 'MARKETING',
};

export const ReviewTypeEnum = {
  PSYCHOTECHNICS: 'PSYCHOTECHNICS',
  GLOBAL: 'GLOBAL',
};

export const PrefilterNumberConditions = {
  GREATER: 'greater',
  EQUAL: 'equal',
  LESS: 'less',
};
