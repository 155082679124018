import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../components/spinner/Spinner';
import useRoles from '../hooks/useRoles';
import './Home.scss';
import { t } from '../i18n/i18n';
import WorkspaceOptions from '../components/WorkspaceOptions/WorkspaceOptions';
import ActiveSearches from '../components/ActiveSearches/ActiveSearches';
import { inHtmlTag } from '../utils/inHtmlTag';
import { getCustomSearchs } from '../API/services/screeningService';
import { getLocalStorageWorkspace } from '../utils/workspaceUtils';
import HomeProfile from '../components/HomeProfile/HomeProfile';
import { getPlanByWorkspaceId, getPlanFeatures } from '../API/services/alkymersService';
import { setPlan, setPlanFeatures } from '../redux/actions/authActions';
import { disableForWorkspaceRole } from '../utils/workspaceRoleValidation';
import { PartnerWorkspaceRole } from '../constants/enums';
import ModalPreferredLanguage from '../components/ModalPreferredLanguage/ModalPreferredLanguage';

const Home = () => {
  const [activeSearches, setActiveSearches] = useState(null);
  const [loadingSearches, setLoadingSearches] = useState(true);
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [shouldSeePlanInformation, setShouldSeePlanInformation] = useState(false);
  const activeWorkspace = getLocalStorageWorkspace();
  const { user, currentWorkspace, plan, currentRole } = useSelector((state) => state.auth);
  const { userRoles } = useRoles();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSearches = async () => {
      try {
        const res = await getCustomSearchs({ offset: 0, active: true, search: '' });
        setActiveSearches(res.collection);
      } catch (error) {
      } finally {
        setLoadingSearches(false);
      }
    };
    fetchSearches();
    const fetchCreditsData = async () => {
      try {
        setLoadingPlan(true);
        const res = await getPlanByWorkspaceId(currentWorkspace);
        dispatch(setPlan(res));
        const featureRes = await getPlanFeatures(res.subscription.subscriptionPending.planId);
        dispatch(setPlanFeatures(featureRes));
      } catch (error) {
        dispatch(setPlan({}));
        dispatch(setPlanFeatures({}));
      } finally {
        setLoadingPlan(false);
      }
    };
    if (currentWorkspace) {
      const isAbleToSeePlanInfo = !disableForWorkspaceRole(currentRole, [
        PartnerWorkspaceRole.OWNER,
        PartnerWorkspaceRole.ADMINISTRATOR,
      ]);

      setShouldSeePlanInformation(isAbleToSeePlanInfo);
      if (isAbleToSeePlanInfo) fetchCreditsData();
    }
  }, [currentWorkspace]);

  if (!userRoles.length || loadingSearches) {
    return <Spinner show />;
  }

  return (
    <div className="home-container">
      <div className="home-display-content">
        <div className="d-block d-lg-flex mt-4 mx-auto justify-content-center home-section-plan-container home-container-gap">
          <HomeProfile isCredits={false} />
          {shouldSeePlanInformation ? (
            loadingPlan ? (
              <Spinner show />
            ) : Object.values(plan).length ? (
              <HomeProfile isCredits />
            ) : null
          ) : null}
        </div>
        {!activeSearches?.length && (
          <div className="w-100 mt-3 d-flex justify-content-center">
            <span
              className="font-montserrat home-welcome-text"
              dangerouslySetInnerHTML={{
                __html: t('HOME_WELCOME_NAME').replace(
                  '{name}',
                  inHtmlTag({
                    tag: 'b',
                    content: user.firstName,
                    className: 'font-montserrat home-welcome-text',
                  })
                ),
              }}
            />
          </div>
        )}
        <WorkspaceOptions
          className="mb-3 px-3"
          showTitle={!activeSearches?.length}
          hideFourth={activeWorkspace && activeWorkspace.createdAt !== activeWorkspace?.updatedAt}
          currentRole={currentRole}
        />
        <ActiveSearches
          setActiveSearches={setActiveSearches}
          setLoading={setLoadingSearches}
          loading={loadingSearches}
          activeSearches={activeSearches}
        />
      </div>
    </div>
  );
};

export default Home;
