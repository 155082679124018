import { useRef, useState } from 'react';
import DropDownArrow from '../../assets/screening/DropDownArrow';
import './QuickConfigurationsItem.scss';

const QuickConfigurationsItem = ({ title, configurationBody }) => {
  const content = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <div className="d-flex w-100 align-items-center justify-content-between">
      <div className="align-items-center d-flex justify-content-between my-1 flex-grow-1 flex-wrap">
        <div className="d-flex bg-gray px-4 py-3 w-100 align-items-center justify-content-between container-min-height">
          <span
            className="text-muted font-montserrat text-xs font-weight-bold text-truncate test-name-max-width"
            data-testid="tg-list-item-name-span"
          >
            {title}
          </span>
          <div className="d-flex align-items-center">
            <button
              onClick={() => setOpen(!open)}
              type="button"
              className="ml-3 bg-transparent border-0 outline-none"
              data-testid="tg-list-item-open-btn"
            >
              <DropDownArrow classNames={`transition-2-ms ${open && 'rotate-180'}`} />
            </button>
          </div>
        </div>
        <div
          ref={content}
          style={{ height: open ? `${content?.current?.scrollHeight}px` : '0px' }}
          className={`px-4 position-relative w-100 flex-column justify-content-center ${
            open && 'py-3 testgroup-description-border h-100'
          } ${open ? 'd-flex' : 'd-none'}`}
        >
          {configurationBody}
        </div>
      </div>
    </div>
  );
};

export default QuickConfigurationsItem;
