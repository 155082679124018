import React, { useEffect, useState } from 'react';
import AssignedWeightIcon from '../../assets/evaluation/AssignedWeightIcon';
import UnassignedWeightIcon from '../../assets/evaluation/UnassignedWeightIcon';
import WeightsEmptyState from '../../assets/evaluation/WeightsEmptyState';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';
import { formatMinutesToHours } from '../../utils/formatMinutesToHours';
import { inHtmlTag } from '../../utils/inHtmlTag';
import TestGroupsListItem from '../TestGroupsListItem/TestGroupsListItem';
import {
  AnswerTypeEnum,
  PlanFeaturesIds,
  ReviewTypeEnum,
  TestGroupTypes,
} from '../../constants/enums';
import QuickConfigurationsItem from '../QuickConfigurationsItem/QuickConfigurationsItem';
import GenericToggle from '../GenericToggle/GenericToggle';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import CalendarDatePicker from '../CalendarDatePicker/CalendarDatePicker';
import { generateHours } from '../../constants/hours';
import DisabledFeature from '../DisabledFeature/DisabledFeature';
import useRoles from '../../hooks/useRoles';
import { Button, Select, Spin } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ModalCustom from '../ModalCustom/ModalCustom';
import VideoUploader from '../UploadVideoFile/VideoUploader';
import ArrowIcon from '../../assets/common/ArrowIcon';

const CreateEvaluationFourthStep = ({
  disableCustomTestGroupWeight,
  handleChangeStep,
  missingTestgroupWeight,
  evaluation,
  handleSetEvaluationData,
}) => {
  const {
    newScreening,
    allTests,
    testsDuration,
    customScreeningTotalTime,
    availableWeight,
    welcomeVideos,
    handleOrder,
    handleDeleteCustomTestGroup,
    handleTests,
    modifyTestWeight,
    handleEditTestGroupItem,
    handleEditCustomTestGroupItem,
    handleDeletePrefilterTest,
    fetchWelcomeVideos,
    addCustomWelcomeVideo,
    deleteCustomWelcomeVideo,
    handleAddVideoInfo,
  } = useCreateScreening();
  const hours = generateHours();
  const { roles, loadRoles, isRole } = useRoles();
  const [withWelcomeVideo, setWithWelcomeVideo] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const { Option } = Select;

  const calculateWeight = () => {
    if (allTests?.length === 1) {
      modifyTestWeight(allTests[0].id, 100);
      return;
    }
    const customTestAmount = allTests?.reduce((count, testGroup) => {
      if (
        testGroup.type === TestGroupTypes.CUSTOM &&
        testGroup.tests?.some((testGroup) =>
          [AnswerTypeEnum.CHECK, AnswerTypeEnum.TEXT_AI, AnswerTypeEnum.WRITE].includes(
            testGroup.answerType
          )
        )
      ) {
        return count + 1;
      }
      return count;
    }, 0);

    const allTestsQuantitatives = allTests?.filter((x) => x?.type === TestGroupTypes.QUANTITATIVE);
    const allRemainingTests = allTests?.filter((x) => x?.type !== TestGroupTypes.QUANTITATIVE);

    const testAmount = allTestsQuantitatives?.length;
    let tgWeight = 100;
    let customTgWeight = 0;

    if (customTestAmount > 0) {
      tgWeight = testAmount > 0 ? Math.floor(99 / testAmount) : 0;
      customTgWeight = 100 - tgWeight * testAmount;
    } else {
      tgWeight = testAmount > 0 ? Math.floor(100 / testAmount) : 0;
    }

    allTestsQuantitatives?.forEach((testGroup, index) => {
      const isLastElement = index === allTestsQuantitatives.length - 1;
      if (isLastElement && customTestAmount === 0 && tgWeight * testAmount < 100) {
        // si la cuenta no da exacta, se suma punto restante
        const lastWeight = 100 - tgWeight * (testAmount - 1);
        modifyTestWeight(testGroup.id, lastWeight);
      } else {
        modifyTestWeight(testGroup.id, tgWeight);
      }
    });

    allRemainingTests.forEach((testGroup) => {
      if (
        testGroup.type === TestGroupTypes.CUSTOM &&
        testGroup.tests?.some((testGroup) =>
          [AnswerTypeEnum.CHECK, AnswerTypeEnum.TEXT_AI, AnswerTypeEnum.VIDEO].includes(
            testGroup.answerType
          )
        )
      ) {
        modifyTestWeight(testGroup.id, customTgWeight);
      }
    });
  };

  const determineAvailableWeightText = () => {
    let text;
    if (availableWeight === 0) {
      if (missingTestgroupWeight) {
        text = t('UNASSIGNED_SOME_TESTS_WEIGHT_DISCLAIMER');
      } else {
        text = t('ALL_WEIGHT_ASSIGNED_DISCLAIMER');
      }
    }
    if (availableWeight > 0)
      text = t('UNASSIGNED_WEIGHT_DISCLAIMER').replace('{weight}', availableWeight);
    if (availableWeight < 0)
      text = t('ASSIGNED_WEIGHT_EXCEDED_LIMIT').replace('{weight}', Math.abs(availableWeight));
    return text;
  };
  const handleEnableDates = () => {
    handleSetEvaluationData({
      ...evaluation,
      emptyDates: !evaluation.emptyDates,
      ...(!evaluation.emptyDates
        ? {
            startDate: {
              day: new Date().toISOString(),
              hours: '00:00',
            },
            endDate: {
              day: new Date('9999-01-01').toISOString(),
              hours: '00:00',
            },
          }
        : {
            startDate: {
              day: new Date().toISOString(),
              hours: '00:00',
            },
            endDate: {
              day: new Date().toISOString(),
              hours: '01:00',
            },
          }),
    });
  };

  const handleToggleDownloadCertificate = () => {
    handleSetEvaluationData({
      ...evaluation,
      allowCertificateDownload: !evaluation.allowCertificateDownload,
    });
  };

  const handleEnableProctoring = () => {
    handleSetEvaluationData({
      ...evaluation,
      proctoringEnabled: !evaluation.proctoringEnabled,
    });
  };

  const handleEnableRequireCompletedProfile = () => {
    handleSetEvaluationData({
      ...evaluation,
      requireCompletedProfile: !evaluation.requireCompletedProfile,
    });
  };

  const handleEnableReminderToCandidates = () => {
    handleSetEvaluationData({
      ...evaluation,
      reminderToCandidates: !evaluation.reminderToCandidates,
    });
  };

  const handleEnablePsychotechnic = (e) => {
    handleSetEvaluationData({
      ...evaluation,
      reviewType:
        evaluation.reviewType === ReviewTypeEnum.GLOBAL
          ? ReviewTypeEnum.PSYCHOTECHNICS
          : ReviewTypeEnum.GLOBAL,
    });
  };

  const handleEnableExclusiveUsers = () => {
    handleSetEvaluationData({
      ...evaluation,
      exclusiveUsers: !evaluation.exclusiveUsers,
    });
  };

  const handleSetStartDate = (e) => {
    handleSetEvaluationData({
      ...evaluation,
      startDate: {
        ...evaluation?.startDate,
        hours: e?.name,
      },
    });
  };

  const handleSetEndDate = (e) => {
    handleSetEvaluationData({
      ...evaluation,
      endDate: {
        ...evaluation?.endDate,
        hours: e?.name,
      },
    });
  };

  const handleFormatPrefilterPreview = () => {
    if (newScreening?.prefilterQuestions?.length > 0) {
      return {
        name: newScreening?.name,
        type: TestGroupTypes.PRE_FILTER,
        tests: newScreening.prefilterQuestions,
      };
    }
  };

  const handleVideoModal = async () => {
    if (!withWelcomeVideo) {
      setWithWelcomeVideo(true);
      setLoadingVideos(true);
      await fetchWelcomeVideos();
      setLoadingVideos(false);
    } else {
      setWithWelcomeVideo(false);
      handleRestoreVideo();
    }
  };

  const handleSelectVideo = (e) => {
    if (e) {
      if (newScreening.welcomeVideoTitle === e.name && newScreening.welcomeVideoUrl === e.id) {
        handleAddVideoInfo({ welcomeVideoUrl: null, welcomeVideoTitle: null });
      } else {
        handleAddVideoInfo({ welcomeVideoUrl: e.id, welcomeVideoTitle: e.name });
      }
    }
  };

  const handleSelectCustomVideo = (e) => {
    handleAddVideoInfo(e);
    addCustomWelcomeVideo(e);
  };

  const handleRestoreVideo = (title) => {
    handleAddVideoInfo({ welcomeVideoTitle: null, welcomeVideoUrl: null });
    setOpenVideoModal(false);
    if (title && title === newScreening.welcomeVideoTitle) deleteCustomWelcomeVideo(title);
  };

  useEffect(() => {
    calculateWeight();
    loadRoles();
    if (newScreening.welcomeVideoUrl) {
      setWithWelcomeVideo(true);
      handleVideoModal();
    }
  }, []);

  useEffect(() => {
    if (
      !welcomeVideos.find((v) => v.id === newScreening.welcomeVideoUrl) &&
      newScreening.welcomeVideoUrl
    ) {
      handleSelectCustomVideo({
        welcomeVideoTitle: newScreening.welcomeVideoTitle,
        welcomeVideoUrl: newScreening.welcomeVideoUrl,
      });
    }
  }, [welcomeVideos]);

  const handleQuestionButton = ({ newItem }) => {
    const itemFound = newScreening?.testGroups?.find((item) => item?.id === newItem?.id);
    if (itemFound) {
      handleEditTestGroupItem({ newTestGroupItem: newItem });
    } else {
      handleEditCustomTestGroupItem({ newCustomTestGroupItem: newItem });
    }
  };
  return (
    <div data-testid="create-evaluation-fourth-step" className="d-flex flex-column">
      <ModalCustom
        show={openVideoModal}
        setShow={setOpenVideoModal}
        onClose={handleRestoreVideo}
        onCancel={(e) => (!uploadingFile ? handleRestoreVideo(e) : () => {})}
        onOk={() => setOpenVideoModal(false)}
        onOkDisabled={
          (!newScreening.welcomeVideoTitle && !newScreening.welcomeVideoUrl) || uploadingFile
        }
        onOkText={t('CREATE_PRE_FILTER_ADD_QUESTION_SELECTED')}
        key="custom-upload"
        onCancelText={t('CONFIRM_MODAL_CANCEL')}
        title={t('CREATE_EVALUATION_WELCOME_VIDEO_ADD_MODAL_TITLE')}
        subtitle={t('CREATE_EVALUATION_WELCOME_VIDEO_ADD_MODAL_SUBTITLE')}
        input={
          <VideoUploader
            t={t}
            setWelcomeVideos={handleSelectCustomVideo}
            newScreening={newScreening}
            deleteCustomWelcomeVideo={handleRestoreVideo}
            uploadingFile={uploadingFile}
            setUploadingFile={setUploadingFile}
          />
        }
      />
      <span
        className="font-weight-600 font-montserrat create-evaluation-step-title text-light-black mb-3"
        data-testid="create-evaluation-fs-title-span"
      >
        {t('CREATE_EVALUATION_FOURTH_STEP_TITLE')}
      </span>
      <span
        className="font-montserrat font-weight-600 text-base text-light-black"
        data-testid="create-evaluation-fs-test-title-span"
      >
        {t('CREATE_EVALUATION_FOURTH_STEP_TESTS_TITLE')}
      </span>
      <span
        className="text-light-black font-weight-400 text-base my-2 font-montserrat"
        data-testid="create-evaluation-fs-test-subtitle-span"
      >
        {t('CREATE_EVALUATION_FOURTH_STEP_TESTS_SUBTITLE')}
      </span>
      {allTests.length ? (
        <div className="mt-2 d-flex flex-column">
          <div className="d-flex my-2">
            {[
              {
                title: t('CREATE_EVALUATION_FOURTH_STEP_TOTAL_EXAMS'),
                value:
                  newScreening?.testGroups?.length +
                  (newScreening?.prefilterQuestions?.length > 0 ? 1 : 0),
                id: '3aa9d972-cf7c-451f-9e91-ca2e8c4ee4cc',
              },
              {
                title: t('CREATE_EVALUATION_FOURTH_STEP_TOTAL_QUESTIONS'),
                value: newScreening?.customTestGroup.length,
                id: 'e5bb4170-0530-4b53-8981-1cf321287d41',
              },
              {
                title: t('CREATE_SCREENING_TOTAL_TIME'),
                value: formatMinutesToHours(customScreeningTotalTime + testsDuration),
                id: 'acb9a004-00f4-45b7-9d5a-be7832c8445e',
              },
            ].map((item, index) => (
              <div key={item.id} className="mr-4">
                <span
                  className="font-montserrat font-italic text-muted text-base font-weight-600"
                  data-testid={`create-evaluation-fs-title-${index}-span`}
                >
                  {item.title}
                </span>
                <span
                  className="total-number-text font-italic font-montserrat font-weight-bold"
                  data-testid={`create-evaluation-fs-value-${index}-span`}
                >
                  {item.value}
                </span>
              </div>
            ))}
          </div>
          {newScreening?.prefilterQuestions?.length > 0 && (
            <TestGroupsListItem
              key={newScreening?.prefilterQuestions?.name}
              testGroup={handleFormatPrefilterPreview()}
              disableCustomWeight
              disableOrderUp
              disableOrderDown
              handleDelete={() => handleDeletePrefilterTest()}
            />
          )}
          {allTests
            ?.sort((a, b) => a.order - b.order)
            ?.map((item, index) => (
              <TestGroupsListItem
                key={item.id}
                testGroup={item}
                disableCustomWeight={item?.type === 'custom' && disableCustomTestGroupWeight}
                disableOrderUp={index === 0}
                disableOrderDown={index === newScreening.allTests.length - 1}
                handleOrder={handleOrder}
                handleQuestionButton={handleQuestionButton}
                handleDelete={() =>
                  item.type === 'custom' ? handleDeleteCustomTestGroup() : handleTests(item)
                }
              />
            ))}
          <span
            data-testid="fourth-step-available-weight"
            className={`ml-auto available-weight-custom-height mt-2 d-flex align-items-center mr-5 pr-5 text-xs font-weight-700 font-montserrat ${
              availableWeight === 0 && !missingTestgroupWeight
                ? 'all-weight-assigned-text'
                : 'unassigned-weight-assigned-text'
            }`}
          >
            {availableWeight === 0 && !missingTestgroupWeight ? (
              <AssignedWeightIcon classNames="mr-2" />
            ) : (
              <UnassignedWeightIcon classNames="mr-2" />
            )}
            {determineAvailableWeightText()}
          </span>
        </div>
      ) : (
        <div className="mt-4 w-66 mx-auto d-flex">
          <WeightsEmptyState testid="fourth-step-empty-state-icon" />
          <div className="d-flex flex-column ml-5">
            <span
              className="font-montserrat text-base font-weight-bold text-xl mb-2"
              data-testid="create-evaluation-fs-empty-state-title-span"
            >
              {t('CREATE_EVALUATION_FOURTH_STEP_EMPTY_STATE_TITLE')}
            </span>
            <span
              className="font-montserrat text-base font-weight-500"
              dangerouslySetInnerHTML={{
                __html: t('CREATE_EVALUATION_FOURTH_STEP_EMPTY_STATE_SUBTITLE').replace(
                  '{bold}',
                  inHtmlTag({
                    tag: 'b',
                    className: 'font-montserrat',
                    content: t('CREATE_EVALUATION_FOURTH_STEP_EMPTY_STATE_SUBTITLE_BOLD'),
                  })
                ),
              }}
              data-testid="create-evaluation-fs-empty-state-subtitle-span"
            />
            <div className="ml-auto mt-3">
              {[
                {
                  text: t('CREATE_EVALUATION_FOURTH_STEP_ADD_TEST_BUTTON'),
                  onClick: () => handleChangeStep(1),
                  id: '50f11e3f-2c0f-4585-bc50-6c0d3ef96708',
                },
                {
                  text: t('CREATE_EVALUATION_FOURTH_STEP_ADD_QUESTION_BUTTON'),
                  onClick: () => handleChangeStep(2),
                  classNames: 'ml-2',
                  id: '88ff2089-1bd4-4c7e-a293-a7988b52258d',
                },
              ].map((item, index) => (
                <button
                  key={item.id}
                  onClick={item.onClick}
                  className={`create-evaluation-secondary-button font-montserrat font-weight-700 bg-transparent py-2 px-4 text-xs ${item.classNames}`}
                  type="button"
                  data-testid={`create-evaluation-fs-button-option-${index}-btn`}
                >
                  {item.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      <div>
        <span className="font-montserrat font-weight-600 text-base text-light-black mb-2">
          {t('CREATE_EVALUATION_OPTIONAL_CONFIGURATION')}
        </span>
        {isRole(roles.TESTGROUPCREATOR) && (
          <QuickConfigurationsItem
            title={t('PSYCHOTECHNIC')}
            configurationBody={
              <div className="my-1">
                <div className="d-flex align-items-center">
                  <span
                    className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                    data-testid="create-evaluation-fs-download-certificate-span"
                  >
                    {t('ENABLE_PSYCHOTECHNIC_DROPDOWN_DESCRIPTION')}
                  </span>
                  <span
                    className="font-montserrat text-xs ml-2 italic py-2 text-muted"
                    data-testid="create-evaluation-fs-download-certificate-span-optional"
                  >
                    {t('IS_OPTIONAL')}
                  </span>
                </div>
                <span
                  className="text-light-black font-weight-300 text-base my-2 font-montserrat"
                  data-testid="create-evaluation-fs-download-certificate-description"
                >
                  {t('ENABLE_PSYCHOTECHNIC_DESCRIPTION')}
                </span>
                <div className="d-flex mt-2 align-items-center">
                  <span className="font-montserrat font-weight-600 text-light-black text-base">
                    {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_BUTTON')}
                  </span>
                  <GenericToggle
                    data-testid="toggle"
                    defaultActive={evaluation.reviewType === ReviewTypeEnum.PSYCHOTECHNICS}
                    actionFunction={handleEnablePsychotechnic}
                  />
                </div>
              </div>
            }
          />
        )}
        <DisabledFeature
          className="d-flex w-100 align-items-center py-0"
          childrenClassname="w-100"
          iconClassName="fourth-step-premium-functions"
          featureId={PlanFeaturesIds.CUSTOM_DATES}
        >
          <QuickConfigurationsItem
            title={t('CREATE_EVALUATION_PLAN_DATES')}
            configurationBody={
              <>
                <span
                  className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                  data-testid="create-evaluation-fs-date-title-span"
                >
                  {t('CREATE_EVALUATION_FIRST_STEP_TEST_DATE_TITLE')}
                </span>
                <span
                  className="text-light-black font-weight-400 text-base my-2 font-montserrat w-100"
                  dangerouslySetInnerHTML={{
                    __html: t('CREATE_EVALUATION_FIRST_STEP_TEST_DATE_SUBTITLE').replace(
                      '{bold}',
                      inHtmlTag({
                        tag: 'b',
                        className: 'font-montserrat',
                        content: t('CREATE_EVALUATION_FIRST_STEP_TEST_DATE_SUBTITLE_BOLD'),
                      })
                    ),
                  }}
                  data-testid="create-evaluation-fs-date-subtitle-span"
                />
                <div className="d-flex align-items-center">
                  <div>
                    <div className="d-flex mt-2 align-items-center">
                      <span className="font-montserrat font-weight-600 text-light-black text-base">
                        {t('CREATE_EVALUATION_QUICK_CONFIG_DATES_ENABLE')}
                      </span>
                      <GenericToggle
                        data-testid="toggle"
                        defaultActive={!evaluation.emptyDates}
                        actionFunction={handleEnableDates}
                      />
                    </div>
                  </div>
                  {!evaluation.emptyDates && (
                    <div className="ml-4">
                      <div className="d-flex justify-content-start">
                        <div className="w-10">
                          <CalendarDatePicker
                            stageData={evaluation}
                            dateType="startDate"
                            buttonClassNames="text-nowrap create-evaluation-datepickers"
                            placeholder={t('CREATE_EVALUATION_START_DATE_DATEPICKER_PLACEHOLDER')}
                            setStageData={handleSetEvaluationData}
                          />
                        </div>
                        <DropdownSelect
                          searchable
                          options={hours}
                          selected={evaluation?.startDate?.hours}
                          selectedPlaceholder
                          setState={handleSetStartDate}
                          className="time-picker-button"
                          placeholderClassname="ml-4"
                        />
                      </div>
                      <div className="d-flex justify-content-start">
                        <div className="w-10">
                          <CalendarDatePicker
                            stageData={evaluation}
                            dateType="endDate"
                            iconClassNames="ml-4 pl-2"
                            buttonClassNames="text-nowrap create-evaluation-datepickers"
                            placeholder={t('CREATE_EVALUATION_END_DATE_DATEPICKER_PLACEHOLDER')}
                            setStageData={handleSetEvaluationData}
                          />
                        </div>
                        <DropdownSelect
                          options={hours}
                          selected={evaluation?.endDate?.hours || '00:00'}
                          selectedPlaceholder
                          setState={handleSetEndDate}
                          className="time-picker-button"
                          placeholderClassname="ml-4"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            }
          />
        </DisabledFeature>
        <DisabledFeature
          className="d-flex w-100 align-items-center py-0"
          childrenClassname="w-100"
          iconClassName="fourth-step-premium-functions"
          featureId={PlanFeaturesIds.PROCTORING}
        >
          <QuickConfigurationsItem
            title={t('CREATE_EVALUATION_QUICK_CONFIG_ENABLE_PROCTORING')}
            configurationBody={
              <div className="my-1">
                <div className="d-flex align-items-center">
                  <span
                    className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                    data-testid="create-evaluation-fs-download-certificate-span"
                  >
                    {t('CREATE_EVALUATION_ENABLE_PROCTORING')}
                  </span>
                  <span
                    className="font-montserrat text-xs ml-2 italic py-2 text-muted"
                    data-testid="create-evaluation-fs-download-certificate-span-optional"
                  >
                    {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_OPTIONAL')}
                  </span>
                </div>
                <div className="d-flex mt-2 align-items-center">
                  <span className="font-montserrat font-weight-600 text-light-black text-base">
                    {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_BUTTON')}
                  </span>
                  <GenericToggle
                    data-testid="toggle"
                    defaultActive={evaluation.proctoringEnabled}
                    actionFunction={handleEnableProctoring}
                  />
                </div>
              </div>
            }
          />
        </DisabledFeature>
        <DisabledFeature
          className="d-flex w-100 align-items-center py-0"
          childrenClassname="w-100"
          iconClassName="fourth-step-premium-functions"
          featureId={PlanFeaturesIds.EXCLUSIVE_TEMP_USERS}
          hideOnDisabled
        >
          <QuickConfigurationsItem
            title={t('CREATE_EVALUATION_QUICK_CONFIG_ENABLE_EXCLUSIVE_TEMP_USERS')}
            configurationBody={
              <div className="my-1">
                <div className="d-flex align-items-center">
                  <span
                    className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                    data-testid="create-evaluation-fs-exclusive-users-span"
                  >
                    {t('CREATE_EVALUATION_ENABLE_EXCLUSIVE_TEMP_USERS')}
                  </span>
                  <span
                    className="font-montserrat text-xs ml-2 italic py-2 text-muted"
                    data-testid="create-evaluation-fs-exclusive-users-span-optional"
                  >
                    {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_OPTIONAL')}
                  </span>
                </div>
                <span
                  className="text-light-black font-weight-400 text-base my-2 font-montserrat"
                  data-testid="create-evaluation-fs-exclusive-users-description"
                >
                  {t('CREATE_EVALUATION_ENABLE_EXCLUSIVE_TEMP_USERS_DESCRIPTION')}
                </span>
                <div className="d-flex mt-2 align-items-center">
                  <span className="font-montserrat font-weight-600 text-light-black text-base">
                    {t('CREATE_EVALUATION_ENABLE_EXCLUSIVE_TEMP_USERS_BUTTON')}
                  </span>
                  <GenericToggle
                    data-testid="toggle"
                    defaultActive={evaluation.exclusiveUsers}
                    actionFunction={handleEnableExclusiveUsers}
                  />
                </div>
              </div>
            }
          />
        </DisabledFeature>
        <QuickConfigurationsItem
          title={t('CREATE_EVALUATION_QUICK_CONFIG_ALLOW_DOWNLOADS')}
          configurationBody={
            <div className="my-1">
              <div className="d-flex align-items-center">
                <span
                  className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                  data-testid="create-evaluation-fs-download-certificate-span"
                >
                  {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES')}
                </span>
                <span
                  className="font-montserrat text-xs ml-2 italic py-2 text-muted"
                  data-testid="create-evaluation-fs-download-certificate-span-optional"
                >
                  {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_OPTIONAL')}
                </span>
              </div>
              <span
                className="text-light-black font-weight-400 text-base my-2 font-montserrat"
                data-testid="create-evaluation-fs-download-certificate-description"
              >
                {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_DESCRIPTION')}
              </span>
              <div className="d-flex mt-2 align-items-center">
                <span className="font-montserrat font-weight-600 text-light-black text-base">
                  {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_BUTTON')}
                </span>
                <GenericToggle
                  data-testid="toggle"
                  defaultActive={evaluation.allowCertificateDownload}
                  actionFunction={handleToggleDownloadCertificate}
                />
              </div>
            </div>
          }
        />
        <QuickConfigurationsItem
          title={t('ENABLE_COMPLETE_PROFILE_DROPDOWN_TITLE')}
          configurationBody={
            <div className="my-1">
              <div className="d-flex align-items-center">
                <span
                  className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                  data-testid="create-evaluation-fs-download-certificate-span"
                >
                  {t('ENABLE_COMPLETE_PROFILE_DROPDOWN_DESCRIPTION')}
                </span>
                <span
                  className="font-montserrat text-xs ml-2 italic py-2 text-muted"
                  data-testid="create-evaluation-fs-download-certificate-span-optional"
                >
                  {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_OPTIONAL')}
                </span>
              </div>
              <span
                className="text-light-black font-weight-400 text-base my-2 font-montserrat"
                data-testid="create-evaluation-fs-download-certificate-description"
              >
                {t('ENABLE_COMPLETE_PROFILE_CERTIFICATES_DESCRIPTION')}
              </span>
              <div className="d-flex mt-2 align-items-center">
                <span className="font-montserrat font-weight-600 text-light-black text-base">
                  {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_BUTTON')}
                </span>
                <GenericToggle
                  data-testid="toggle"
                  defaultActive={evaluation.requireCompletedProfile}
                  actionFunction={handleEnableRequireCompletedProfile}
                />
              </div>
            </div>
          }
        />
        <DisabledFeature
          className="d-flex w-100 align-items-center"
          childrenClassname="w-100"
          iconClassName="fourth-step-premium-functions"
          featureId={PlanFeaturesIds.TEXT_AI}
        >
          <QuickConfigurationsItem
            title={t('CREATE_EVALUATION_WELCOME_VIDEO')}
            configurationBody={
              <>
                <span
                  className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                  data-testid="create-evaluation-fs-date-title-span"
                >
                  {t('CREATE_EVALUATION_WELCOME_VIDEO_TITLE')}
                </span>
                <span
                  className="text-light-black font-weight-400 text-base my-2 font-montserrat w-100"
                  data-testid="create-evaluation-fs-date-subtitle-span"
                >
                  {t('CREATE_EVALUATION_WELCOME_VIDEO_SUBTITLE')}
                </span>
                <div className="d-flex mt-1 align-items-center">
                  <span className="font-montserrat font-weight-600 text-light-black text-base">
                    {t('CREATE_EVALUATION_QUICK_CONFIG_DATES_ENABLE')}
                  </span>
                  <GenericToggle
                    data-testid="toggle-welcome-video"
                    className="my-1"
                    defaultActive={withWelcomeVideo}
                    actionFunction={() => handleVideoModal()}
                  />
                  {withWelcomeVideo ? (
                    loadingVideos ? (
                      <>
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 30,
                                color: 'maya-blue',
                                marginLeft: '4em',
                              }}
                              spin
                            />
                          }
                        />
                      </>
                    ) : (
                      <>
                        <div className="ml-4 align-items-center">
                          <div className="d-flex justify-content-start">
                            <div className="d-flex justify-content-start">
                              <Select
                                placeholder={t(
                                  'CREATE_EVALUATION_WELCOME_VIDEO_SELECT_PLACEHOLDER'
                                )}
                                onSelect={(e) => {
                                  const video = welcomeVideos.find((v) => v.id === e);
                                  handleSelectVideo(video);
                                }}
                                value={newScreening.welcomeVideoUrl}
                                key="welcome-video-selector"
                                showArrow
                                suffixIcon={<ArrowIcon />}
                              >
                                {welcomeVideos?.map((video) => (
                                  <Option value={video.id} key={video.id}>
                                    {video?.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>{t('CREATE_PRE_FILTER_ADD_QUESTION_SELECTED')}</Tooltip>
                              }
                            >
                              <Button
                                type="link"
                                className="mx-3"
                                icon={<PlusOutlined />}
                                data-id="add-welcome-video-options"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpenVideoModal(true);
                                }}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </>
            }
          />
        </DisabledFeature>
        <QuickConfigurationsItem
          title={t('REMEMBER_TEST')}
          configurationBody={
            <div className="my-1">
              <div className="d-flex align-items-center">
                <span
                  className="font-montserrat font-weight-600 text-base py-2 text-light-black"
                  data-testid="create-evaluation-fs-download-certificate-span"
                >
                  {t('REMEMBER_TEST_QUESTION')}
                </span>
                <span
                  className="font-montserrat text-xs ml-2 italic py-2 text-muted"
                  data-testid="create-evaluation-fs-download-certificate-span-optional"
                >
                  {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_OPTIONAL')}
                </span>
              </div>
              <span
                className="text-light-black font-weight-400 text-base my-2 font-montserrat"
                data-testid="create-evaluation-fs-download-certificate-description"
              >
                {t('REMEMBER_TEST_DETAILS')}
              </span>
              <div className="d-flex mt-2 align-items-center">
                <span className="font-montserrat font-weight-600 text-light-black text-base">
                  {t('CREATE_EVALUATION_ALLOW_DOWNLOAD_CERTIFICATES_BUTTON')}
                </span>
                <GenericToggle
                  data-testid="toggle"
                  defaultActive={evaluation.reminderToCandidates}
                  actionFunction={handleEnableReminderToCandidates}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default CreateEvaluationFourthStep;
